// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Gatsby from "gatsby";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

var ScrollContainer = { };

function createKey(param) {
  return String(Math.random()).substr(2, 6);
}

function safeMakeEvent(eventName) {
  if (typeof Event === "function") {
    return new Event(eventName);
  } else {
    var $$event = document.createEvent("Event");
    $$event.initEvent(eventName, true, true);
    return $$event;
  }
}

function navigate(state, replaceOpt, path) {
  var replace = replaceOpt !== undefined ? replaceOpt : false;
  var nextState = state !== undefined ? ({
        key: createKey(/* () */0),
        state: Caml_option.valFromOption(state)
      }) : ({ });
  Gatsby.navigate(path, {
        replace: replace,
        state: nextState
      });
  return /* () */0;
}

function getFullPath($$location) {
  var search = $$location.search;
  var hash = $$location.hash;
  var pathname = $$location.pathname;
  var pathWithSearch = search === "" ? pathname : "" + (String(pathname) + ("?" + (String(search) + "")));
  if (hash === "") {
    return pathWithSearch;
  } else {
    return "" + (String(pathWithSearch) + ("" + (String(hash) + "")));
  }
}

var keyLength = 6;

export {
  ScrollContainer ,
  keyLength ,
  createKey ,
  safeMakeEvent ,
  navigate ,
  getFullPath ,
  
}
/* gatsby Not a pure module */
