// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Gatsby from "gatsby";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Styles from "src/styles";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as ReactHelmet from "react-helmet";
import * as Router from "@reach/router";
import * as Logo_with_backgroundJpg from "static/images/logo_with_background.jpg";

var GlobalStyles = { };

var wrapper = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.position(/* relative */903134412),
          /* :: */[
            Css.height(Css.pct(100)),
            /* :: */[
              Css.flex3(1, 1, /* `rem */[
                    5691738,
                    0.00000001
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var header = Curry._1(Css.style, /* :: */[
      Css.position(/* fixed */10615156),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.right(/* zero */-789508312),
          /* :: */[
            Css.left(/* zero */-789508312),
            /* [] */0
          ]
        ]
      ]
    ]);

var Styles$1 = {
  wrapper: wrapper,
  header: header
};

var query = (Gatsby.graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title,
          siteURL
        }
      }
    }
  `);

var defaultDescription = "Glazed is a team of elite web and mobile developers that partner with the world’s leading companies.";

var baseMetaTags = [{
    name: "keywords",
    content: "reasonml, bucklescript, react"
  }];

var make = React.memo((function (Props) {
        var titleOverride = Props.title;
        var descriptionOpt = Props.description;
        var children = Props.children;
        var description = descriptionOpt !== undefined ? descriptionOpt : defaultDescription;
        var queryResult = Gatsby.useStaticQuery(query);
        var siteURL = queryResult.site.siteMetadata.siteURL;
        var defaultTitle = queryResult.site.siteMetadata.title;
        var $$location = Router.useLocation();
        var title = Belt_Option.getWithDefault(titleOverride, defaultTitle);
        var meta = Belt_Array.concat(baseMetaTags, [
              {
                name: "description",
                content: description
              },
              {
                name: "og:title",
                content: title
              },
              {
                name: "og:description",
                content: description
              },
              {
                name: "og:image",
                content: siteURL + Logo_with_backgroundJpg.default
              },
              {
                name: "og:url",
                content: siteURL + $$location.pathname
              },
              {
                name: "og:site_name",
                content: defaultTitle
              },
              {
                name: "twitter:card",
                content: "summary_large_image"
              },
              {
                name: "twitter:image:alt",
                content: "Glazed logo."
              }
            ]);
        return React.createElement(React.Fragment, undefined, React.createElement(ReactHelmet.default, {
                        title: title,
                        meta: meta,
                        children: null
                      }, React.createElement("html", {
                            lang: "en"
                          }), React.createElement("link", {
                            href: siteURL + $$location.pathname,
                            rel: "canonical"
                          })), React.createElement(Styles.default, { }), children);
      }));

var $$default = make;

export {
  GlobalStyles ,
  Styles$1 as Styles,
  query ,
  defaultDescription ,
  baseMetaTags ,
  make ,
  $$default ,
  $$default as default,
  
}
/* wrapper Not a pure module */
