// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Css_Core from "bs-css/lib/es6/src/Css_Core.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Theme$GlazedWebsite from "../Theme.js";
import * as Utils$GlazedWebsite from "./Utils.js";

var backgrounds = {
  BrightBg: 0,
  BlueBg: 1,
  ImageBg: 2
};

var base = Curry._1(Css.style, /* :: */[
      Css.padding2(Css.rem(0.875), Css.rem(3.0)),
      /* [] */0
    ]);

var common = Curry._1(Css.style, /* :: */[
      Css.border(Css.px(1), /* none */-922086728, /* transparent */582626130),
      /* :: */[
        Css.background(/* none */-922086728),
        /* [] */0
      ]
    ]);

function primary(isDisabled) {
  return Curry._1(Css.style, /* :: */[
              Css.opacity(isDisabled ? 0.4 : 1.0),
              /* :: */[
                Css.transition(1000, undefined, undefined, "box-shadow"),
                /* :: */[
                  Css.backgroundColor(Css.hex(Theme$GlazedWebsite.Colors.glazedBlueDarkerish)),
                  /* :: */[
                    Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
                    /* :: */[
                      Css.fontFamily(/* `custom */[
                            1066567601,
                            Theme$GlazedWebsite.Fonts.heading
                          ]),
                      /* :: */[
                        Css.fontSize(Css.rem(0.75)),
                        /* :: */[
                          Css.lineHeight(Css.rem(0.75)),
                          /* :: */[
                            Css.padding2(Css.rem(0.875), Css.rem(3.0)),
                            isDisabled ? /* [] */0 : /* :: */[
                                Css.hover(/* :: */[
                                      Css.boxShadow(Css_Core.Shadow.box(undefined, Css.px(10), Css.px(20), undefined, undefined, Css.rgba(0, 0, 0, 0.15))),
                                      /* :: */[
                                        Css.transition(1000, undefined, undefined, "box-shadow"),
                                        /* [] */0
                                      ]
                                    ]),
                                /* [] */0
                              ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

function secondaryForBg(bg) {
  switch (bg) {
    case /* BrightBg */0 :
        return Curry._1(Css.style, /* [] */0);
    case /* BlueBg */1 :
        return Curry._1(Css.style, /* :: */[
                    Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
                    /* :: */[
                      Css.border(Css.px(1), /* solid */12956715, Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
                      /* [] */0
                    ]
                  ]);
    case /* ImageBg */2 :
        return Curry._1(Css.style, /* :: */[
                    Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
                    /* :: */[
                      Css.border(Css.px(1), /* solid */12956715, Css.hex(Theme$GlazedWebsite.Colors.glazedBlueDarkerish)),
                      /* [] */0
                    ]
                  ]);
    
  }
}

function secondary(bg, isDisabled) {
  return Curry._1(Css.merge, /* :: */[
              Curry._1(Css.style, /* :: */[
                    Css.color(Css.hex(Theme$GlazedWebsite.Colors.darkGreyDarker)),
                    /* :: */[
                      Css.opacity(isDisabled ? 0.4 : 1.0),
                      /* :: */[
                        Css.padding2(Css.rem(0.875), Css.rem(3.0)),
                        /* :: */[
                          Css.border(Css.px(1), /* solid */12956715, Css.hex(Theme$GlazedWebsite.Colors.glazedBlueDarkerish)),
                          /* :: */[
                            Css.backgroundColor(/* transparent */582626130),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]),
              /* :: */[
                secondaryForBg(bg),
                /* [] */0
              ]
            ]);
}

function tertiary(_isDisabled) {
  return Curry._1(Css.style, /* :: */[
              Css.color(Css.hex(Theme$GlazedWebsite.Colors.glazedBabyBlue)),
              /* :: */[
                Css.borderWidth(Css.px(0)),
                /* :: */[
                  Css.fontFamily(/* `custom */[
                        1066567601,
                        Theme$GlazedWebsite.Fonts.heading
                      ]),
                  /* :: */[
                    Css.fontSize(Css.rem(0.75)),
                    /* :: */[
                      Css.lineHeight(Css.rem(1.5)),
                      /* :: */[
                        Css.textAlign(/* left */-944764921),
                        /* :: */[
                          Css.padding2(Css.rem(0.875), Css.rem(3.0)),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

var Styles = {
  base: base,
  common: common,
  primary: primary,
  secondaryForBg: secondaryForBg,
  secondary: secondary,
  tertiary: tertiary
};

function Button(Props) {
  var children = Props.children;
  var _type = Props.type;
  var bgColorOpt = Props.bgColor;
  var isDisabledOpt = Props.isDisabled;
  var className = Props.className;
  var onClick = Props.onClick;
  var type_ = Props.type_;
  var bgColor = bgColorOpt !== undefined ? bgColorOpt : /* BrightBg */0;
  var isDisabled = isDisabledOpt !== undefined ? isDisabledOpt : false;
  var ownStyles;
  if (_type !== undefined) {
    switch (_type) {
      case /* Primary */0 :
          ownStyles = primary(isDisabled);
          break;
      case /* Secondary */1 :
          ownStyles = secondary(bgColor, isDisabled);
          break;
      case /* Tertiary */2 :
          ownStyles = tertiary(isDisabled);
          break;
      
    }
  } else {
    ownStyles = base;
  }
  var onClickHandler = onClick !== undefined ? (
      isDisabled ? (function (param) {
            return /* () */0;
          }) : onClick
    ) : (function (param) {
        return /* () */0;
      });
  var tmp = {
    onClick: onClickHandler
  };
  var tmp$1 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        Curry._1(Css.merge, /* :: */[
              common,
              /* :: */[
                ownStyles,
                /* [] */0
              ]
            ]),
        /* :: */[
          className,
          /* [] */0
        ]
      ]);
  if (tmp$1 !== undefined) {
    tmp.className = Caml_option.valFromOption(tmp$1);
  }
  if (type_ !== undefined) {
    tmp.type = Caml_option.valFromOption(type_);
  }
  return React.createElement("button", tmp, children);
}

var make = Button;

var $$default = Button;

export {
  backgrounds ,
  Styles ,
  make ,
  $$default ,
  $$default as default,
  
}
/* base Not a pure module */
