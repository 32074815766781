// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as FormValidations$GlazedWebsite from "./FormValidations.js";

function useRenderer(baseRenderFieldOpt, controlProps, param) {
  var baseRenderField = baseRenderFieldOpt !== undefined ? baseRenderFieldOpt : (function (param, param$1, param$2) {
        return null;
      });
  var onFieldVisit = controlProps.onFieldVisit;
  var onFieldTouch = controlProps.onFieldTouch;
  var onFieldFocus = controlProps.onFieldFocus;
  var onFieldBlur = controlProps.onFieldBlur;
  var onSubmit = controlProps.onSubmit;
  var onChange = controlProps.onChange;
  var visited = controlProps.visited;
  var touched = controlProps.touched;
  var formValidationResult = controlProps.formValidationResult;
  var values = controlProps.values;
  var schema = controlProps.schema;
  var makeOnFieldChange = React.useCallback((function (fieldName) {
          return (function (fieldSchema, nextValue) {
              return Curry._2(onChange, Curry._3(FormValidations$GlazedWebsite.Values.set, values, fieldName, nextValue), {
                          fieldName: fieldName,
                          fieldSchema: fieldSchema,
                          values: values,
                          nextValue: Caml_option.some(nextValue)
                        });
            });
        }), /* tuple */[
        values,
        onChange
      ]);
  var makeOnFieldFocus = React.useCallback((function (fieldName) {
          return (function (fieldSchema, param) {
              var eventMetadata = {
                fieldName: fieldName,
                fieldSchema: fieldSchema,
                values: values,
                nextValue: undefined
              };
              if (!Curry._2(FormValidations$GlazedWebsite.Visited.has, visited, fieldName)) {
                Curry._1(onFieldVisit, eventMetadata);
              }
              return Curry._1(onFieldFocus, eventMetadata);
            });
        }), /* tuple */[
        values,
        visited,
        onFieldFocus,
        onFieldVisit
      ]);
  var makeOnFieldBlur = React.useCallback((function (fieldName) {
          return (function (fieldSchema, param) {
              var eventMetadata = {
                fieldName: fieldName,
                fieldSchema: fieldSchema,
                values: values,
                nextValue: undefined
              };
              if (!Curry._2(FormValidations$GlazedWebsite.Touched.has, touched, fieldName)) {
                Curry._1(onFieldTouch, eventMetadata);
              }
              return Curry._1(onFieldBlur, eventMetadata);
            });
        }), /* tuple */[
        values,
        touched,
        onFieldBlur,
        onFieldTouch
      ]);
  var onSubmit$1 = React.useCallback((function ($$event) {
          $$event.preventDefault();
          return Curry._1(onSubmit, values);
        }), /* tuple */[
        onSubmit,
        values
      ]);
  var getPropsForField = React.useCallback((function (fieldName) {
          return (function (fieldSchema) {
              var value = Curry._2(FormValidations$GlazedWebsite.Values.get, values, fieldName);
              var error = formValidationResult ? Curry._2(FormValidations$GlazedWebsite.Errors.get, formValidationResult[0], fieldName) : undefined;
              return {
                      key: fieldName,
                      name: fieldName,
                      value: value,
                      error: error,
                      onChange: Curry._2(makeOnFieldChange, fieldName, fieldSchema),
                      onFocus: Curry._2(makeOnFieldFocus, fieldName, fieldSchema),
                      onBlur: Curry._2(makeOnFieldBlur, fieldName, fieldSchema)
                    };
            });
        }), /* tuple */[
        values,
        formValidationResult,
        makeOnFieldChange,
        makeOnFieldFocus,
        makeOnFieldBlur
      ]);
  var getPropsForFieldWithName = React.useCallback((function (fieldName) {
          var match = Curry._2(FormValidations$GlazedWebsite.Schema.get, schema, fieldName);
          if (match !== undefined) {
            return Curry._2(getPropsForField, fieldName, match);
          }
          
        }), /* tuple */[
        schema,
        getPropsForField
      ]);
  var renderField = React.useCallback((function (fieldName) {
          return (function (fieldSchema) {
              return Curry._3(baseRenderField, fieldName, fieldSchema, Curry._2(getPropsForField, fieldName, fieldSchema));
            });
        }), /* tuple */[
        baseRenderField,
        values,
        formValidationResult,
        makeOnFieldChange,
        makeOnFieldFocus,
        makeOnFieldBlur
      ]);
  var renderFieldWithName = React.useCallback((function (fieldName) {
          var match = Curry._2(FormValidations$GlazedWebsite.Schema.get, schema, fieldName);
          if (match !== undefined) {
            return Curry._2(renderField, fieldName, match);
          } else {
            return null;
          }
        }), /* tuple */[
        schema,
        renderField
      ]);
  var renderFields = React.useCallback((function (param) {
          return Belt_Array.map(Curry._1(FormValidations$GlazedWebsite.Schema.toArray, schema), (function (param) {
                        return Curry._2(renderField, param[0], param[1]);
                      }));
        }), /* tuple */[
        schema,
        renderField
      ]);
  return {
          getPropsForField: getPropsForFieldWithName,
          onSubmit: onSubmit$1,
          renderField: renderFieldWithName,
          renderFields: renderFields
        };
}

export {
  useRenderer ,
  
}
/* react Not a pure module */
