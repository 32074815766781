// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as FormValidations$GlazedWebsite from "./FormValidations.js";

function getAllFieldsTouched(schema) {
  return Curry._3(FormValidations$GlazedWebsite.Schema.reduce, schema, FormValidations$GlazedWebsite.Touched.make(/* () */0), (function (result, fieldName, _fieldSchema) {
                return Curry._3(FormValidations$GlazedWebsite.Touched.set, result, fieldName, true);
              }));
}

function getInitialTouched(schema, validateOnInit) {
  if (validateOnInit) {
    return getAllFieldsTouched(schema);
  } else {
    return FormValidations$GlazedWebsite.Touched.make(/* () */0);
  }
}

function getInitialValues(schema) {
  return Curry._2(FormValidations$GlazedWebsite.Values.map, FormValidations$GlazedWebsite.Values.fromArray(Curry._1(FormValidations$GlazedWebsite.Schema.toArray, schema)), (function (_fieldName, _fieldSchema) {
                return "";
              }));
}

function getValidationResult(schema, values, touched, additionalErrors) {
  var formValidationResult = FormValidations$GlazedWebsite.validate(schema, values, touched);
  if (additionalErrors !== undefined) {
    var errors = Caml_option.valFromOption(additionalErrors);
    if (formValidationResult) {
      return /* FormInvalid */[Curry._3(FormValidations$GlazedWebsite.Errors.reduce, errors, formValidationResult[0], (function (result, fieldName, error) {
                      return Curry._3(FormValidations$GlazedWebsite.Errors.set, result, fieldName, error);
                    }))];
    } else {
      return /* FormInvalid */[errors];
    }
  } else {
    return formValidationResult;
  }
}

function wrapOnChange(onChange, $$event) {
  return Curry._1(onChange, $$event.target.value);
}

function wrapOnFocus(onFocus, _event) {
  return Curry._1(onFocus, /* () */0);
}

function wrapOnBlur(onBlur, _event) {
  return Curry._1(onBlur, /* () */0);
}

export {
  getAllFieldsTouched ,
  getInitialTouched ,
  getInitialValues ,
  getValidationResult ,
  wrapOnChange ,
  wrapOnFocus ,
  wrapOnBlur ,
  
}
/* FormValidations-GlazedWebsite Not a pure module */
