// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Utils$GlazedWebsite from "../Utils.js";
import * as ResizeMonitor$GlazedWebsite from "./ResizeMonitor.js";

var wrapper = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* [] */0
    ]);

var Styles = {
  wrapper: wrapper
};

function ResizeMonitorContainer(Props) {
  var children = Props.children;
  var onResize = Props.onResize;
  var className = Props.className;
  var id = Props.id;
  var innerRef = Props.innerRef;
  var style = Props.style;
  var componentTagOpt = Props.componentTag;
  var componentTag = componentTagOpt !== undefined ? componentTagOpt : "div";
  var tmp = { };
  var tmp$1 = Belt_Option.map(innerRef, (function (prim) {
          return prim;
        }));
  if (tmp$1 !== undefined) {
    tmp.ref = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        wrapper,
        /* :: */[
          className,
          /* [] */0
        ]
      ]);
  if (tmp$2 !== undefined) {
    tmp.className = Caml_option.valFromOption(tmp$2);
  }
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  var tmp$3 = { };
  if (onResize !== undefined) {
    tmp$3.onResize = Caml_option.valFromOption(onResize);
  }
  return React.createElement(componentTag, tmp, React.createElement(React.Fragment, {
                  children: null
                }, children, React.createElement(ResizeMonitor$GlazedWebsite.make, tmp$3)));
}

var make = ResizeMonitorContainer;

export {
  Styles ,
  make ,
  
}
/* wrapper Not a pure module */
