// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Css_Core from "bs-css/lib/es6/src/Css_Core.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Theme$GlazedWebsite from "./Theme.js";
import * as Utils$GlazedWebsite from "./shared/Utils.js";
import * as Header$GlazedWebsite from "./Header.js";
import * as ScrollConnectors$GlazedWebsite from "./shared/scroll-container/ScrollConnectors.js";

var header = Curry._1(Css.style, /* :: */[
      Css.position(/* fixed */10615156),
      /* :: */[
        Css.backgroundColor(Css.hex(Theme$GlazedWebsite.Colors.whiteTurquoise)),
        /* :: */[
          Css.transition(200, 0, /* easeOut */715748672, "all"),
          /* [] */0
        ]
      ]
    ]);

var headerWithShadow = Curry._1(Css.style, /* :: */[
      Css.boxShadow(Css_Core.Shadow.box(undefined, Css.px(7), Css.px(15), undefined, undefined, Css.rgba(0, 0, 0, 0.04))),
      /* [] */0
    ]);

var Styles = {
  header: header,
  headerWithShadow: headerWithShadow
};

function useHeaderPosition(param) {
  var scrollValues = ScrollConnectors$GlazedWebsite.useClosestScrollValues(/* () */0);
  var scrollTop = scrollValues.position.scrollTop;
  var lastScrollDataRef = React.useRef(/* tuple */[
        scrollTop,
        /* Up */0
      ]);
  var match = lastScrollDataRef.current;
  var lastScrollTop = match[0];
  var scrollDirection = scrollTop > lastScrollTop + 15 ? /* Down */1 : (
      lastScrollTop <= scrollTop ? match[1] : /* Up */0
    );
  var shouldHaveBackground = scrollTop > 15;
  lastScrollDataRef.current = /* tuple */[
    scrollTop,
    scrollDirection
  ];
  return /* tuple */[
          scrollDirection,
          shouldHaveBackground
        ];
}

function FloatingHeader(Props) {
  var innerRef = Props.innerRef;
  var className = Props.className;
  var componentAtTheRight = Props.componentAtTheRight;
  var onNavBarLinkClick = Props.onNavBarLinkClick;
  var useDarkNavBarLinksOpt = Props.useDarkNavBarLinks;
  var currentPageIndex = Props.currentPageIndex;
  var useDarkNavBarLinks = useDarkNavBarLinksOpt !== undefined ? useDarkNavBarLinksOpt : false;
  var match = useHeaderPosition(/* () */0);
  var scrollDirection = match[0];
  var headerStyle = React.useMemo((function () {
          if (scrollDirection) {
            return {
                    transform: "translateY(-100%)"
                  };
          }
          
        }), [scrollDirection]);
  var headerClassName = match[1] ? Curry._1(Css.merge, /* :: */[
          header,
          /* :: */[
            headerWithShadow,
            /* [] */0
          ]
        ]) : header;
  var tmp = {
    useDarkNavBarLinks: useDarkNavBarLinks
  };
  if (innerRef !== undefined) {
    tmp.innerRef = Caml_option.valFromOption(innerRef);
  }
  var tmp$1 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        headerClassName,
        /* :: */[
          className,
          /* [] */0
        ]
      ]);
  if (tmp$1 !== undefined) {
    tmp.className = Caml_option.valFromOption(tmp$1);
  }
  if (headerStyle !== undefined) {
    tmp.style = Caml_option.valFromOption(headerStyle);
  }
  if (componentAtTheRight !== undefined) {
    tmp.componentAtTheRight = Caml_option.valFromOption(componentAtTheRight);
  }
  if (onNavBarLinkClick !== undefined) {
    tmp.onNavBarLinkClick = Caml_option.valFromOption(onNavBarLinkClick);
  }
  if (currentPageIndex !== undefined) {
    tmp.currentPageIndex = Caml_option.valFromOption(currentPageIndex);
  }
  return React.createElement(Header$GlazedWebsite.make, tmp);
}

var scrollDirectionOffset = 15;

var make = FloatingHeader;

var $$default = FloatingHeader;

export {
  Styles ,
  scrollDirectionOffset ,
  make ,
  $$default ,
  $$default as default,
  useHeaderPosition ,
  
}
/* header Not a pure module */
