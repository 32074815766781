// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Gatsby from "gatsby";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Router from "@reach/router";
import * as Theme$GlazedWebsite from "./Theme.js";
import * as Utils$GlazedWebsite from "./shared/Utils.js";
import * as Button$GlazedWebsite from "./shared/Button.js";
import * as Header$GlazedWebsite from "./Header.js";
import * as MobileFooter$GlazedWebsite from "./MobileFooter.js";
import * as JobOfferModal$GlazedWebsite from "./JobOfferModal.js";
import * as SayHelloModal$GlazedWebsite from "./SayHelloModal.js";
import * as FloatingHeader$GlazedWebsite from "./FloatingHeader.js";
import * as ModalsController$GlazedWebsite from "./shared/modals/ModalsController.js";
import * as OpenSayHelloModalHook$GlazedWebsite from "./OpenSayHelloModalHook.js";

var wrapper = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.position(/* relative */903134412),
          /* :: */[
            Css.backgroundColor(Css.hex(Theme$GlazedWebsite.Colors.white)),
            /* :: */[
              Css.flex3(1, 1, /* `rem */[
                    5691738,
                    0.00000001
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var header = Curry._1(Css.style, /* :: */[
      Css.position(/* fixed */10615156),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.right(/* `px */[
                25096,
                14
              ]),
          /* :: */[
            Css.left(/* zero */-789508312),
            /* :: */[
              Css.zIndex(1),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var padFloatingNav = Curry._1(Css.style, /* :: */[
      Css.paddingTop(Css.px(75)),
      /* :: */[
        Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
              Css.paddingTop(Css.px(117)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

function sayHelloButton(useDarkNavBarLinks) {
  return Curry._1(Css.style, /* :: */[
              Css.display(/* none */-922086728),
              /* :: */[
                Css.padding2(/* `rem */[
                      5691738,
                      0.875
                    ], /* `rem */[
                      5691738,
                      1.75
                    ]),
                /* :: */[
                  Css.color(Css.hex(useDarkNavBarLinks ? Theme$GlazedWebsite.Colors.darkGreyDarker : Theme$GlazedWebsite.Colors.almostWhite)),
                  /* :: */[
                    Css.fontSize(/* `rem */[
                          5691738,
                          0.75
                        ]),
                    /* :: */[
                      Css.fontFamily(/* `custom */[
                            1066567601,
                            Theme$GlazedWebsite.Fonts.heading
                          ]),
                      /* :: */[
                        Css.pointerEvents(/* auto */-1065951377),
                        /* :: */[
                          Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                                Css.display(/* block */888960333),
                                /* [] */0
                              ]),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

var mobileCloseButtonWrapper = Curry._1(Css.style, /* :: */[
      Css.flex3(0, 0, /* auto */-1065951377),
      /* [] */0
    ]);

var mobileCloseButton = Curry._1(Css.style, /* :: */[
      Css.padding(Css.rem(0.625)),
      /* :: */[
        Css.margin2(/* zero */-789508312, Css.rem(0.9375)),
        /* :: */[
          Css.background(/* none */-922086728),
          /* :: */[
            Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
            /* :: */[
              Css.fontFamily(/* `custom */[
                    1066567601,
                    Theme$GlazedWebsite.Fonts.heading
                  ]),
              /* :: */[
                Css.fontSize(Css.rem(0.625)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  wrapper: wrapper,
  header: header,
  padFloatingNav: padFloatingNav,
  sayHelloButton: sayHelloButton,
  mobileCloseButtonWrapper: mobileCloseButtonWrapper,
  mobileCloseButton: mobileCloseButton
};

var make = React.memo((function (Props) {
        var children = Props.children;
        var className = Props.className;
        var headerClassName = Props.headerClassName;
        var headerStyle = Props.headerStyle;
        var useDarkNavBarLinksOpt = Props.useDarkNavBarLinks;
        var useFloatingNavBarOpt = Props.useFloatingNavBar;
        var currentPageIndexOpt = Props.currentPageIndex;
        var onHeaderResize = Props.onHeaderResize;
        var useDarkNavBarLinks = useDarkNavBarLinksOpt !== undefined ? useDarkNavBarLinksOpt : false;
        var useFloatingNavBar = useFloatingNavBarOpt !== undefined ? useFloatingNavBarOpt : false;
        var currentPageIndex = currentPageIndexOpt !== undefined ? currentPageIndexOpt : 0;
        var match = OpenSayHelloModalHook$GlazedWebsite.useOpenSayHelloModal(/* () */0);
        var openSayHelloModal = match[1];
        var sayHelloModalUrl = match[0];
        var openedModalRef = React.useRef(undefined);
        var modalsAPI = ModalsController$GlazedWebsite.useContextAPI(/* () */0);
        var $$location = Router.useLocation();
        var selectedModal = new URLSearchParams($$location.search).get("modal");
        var headerComponentAtTheRight = React.useMemo((function () {
                return React.createElement(Gatsby.Link, {
                            to: sayHelloModalUrl,
                            children: React.createElement(Button$GlazedWebsite.make, {
                                  children: "> say hello",
                                  type: /* Secondary */1,
                                  className: sayHelloButton(useDarkNavBarLinks)
                                }),
                            onClick: openSayHelloModal,
                            state: {
                              state: {
                                preventDefaultScrollBehavior: true
                              }
                            }
                          });
              }), /* tuple */[
              sayHelloModalUrl,
              openSayHelloModal,
              useDarkNavBarLinks
            ]);
        var footerComponentAtTheRight = React.useMemo((function () {
                return React.createElement(Gatsby.Link, {
                            to: sayHelloModalUrl,
                            className: mobileCloseButtonWrapper,
                            children: React.createElement(Button$GlazedWebsite.make, {
                                  children: "> say hello",
                                  type: /* Secondary */1,
                                  className: mobileCloseButton
                                }),
                            onClick: openSayHelloModal
                          });
              }), /* tuple */[
              sayHelloModalUrl,
              openSayHelloModal
            ]);
        React.useEffect((function () {
                var match = openedModalRef.current;
                if (match !== undefined) {
                  Curry._1(modalsAPI.closeModal, match);
                }
                if (!(selectedModal == null)) {
                  switch (selectedModal) {
                    case "job-offer" :
                        var modal = Curry._1(modalsAPI.openModal, (function (modalId, onClose) {
                                return React.createElement(JobOfferModal$GlazedWebsite.make, {
                                            modalId: modalId,
                                            onClose: onClose
                                          });
                              }));
                        openedModalRef.current = modal;
                        break;
                    case "say-hello" :
                        var modal$1 = Curry._1(modalsAPI.openModal, (function (modalId, onClose) {
                                return React.createElement(SayHelloModal$GlazedWebsite.make, {
                                            modalId: modalId,
                                            onClose: onClose
                                          });
                              }));
                        openedModalRef.current = modal$1;
                        break;
                    default:
                      
                  }
                }
                return ;
              }), [(selectedModal == null) ? undefined : Caml_option.some(selectedModal)]);
        var tmp = { };
        var tmp$1 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
              wrapper,
              /* :: */[
                className,
                /* :: */[
                  useFloatingNavBar ? padFloatingNav : "",
                  /* [] */0
                ]
              ]
            ]);
        if (tmp$1 !== undefined) {
          tmp.className = Caml_option.valFromOption(tmp$1);
        }
        var tmp$2;
        if (useFloatingNavBar) {
          var tmp$3 = {
            componentAtTheRight: headerComponentAtTheRight,
            useDarkNavBarLinks: useDarkNavBarLinks,
            currentPageIndex: currentPageIndex
          };
          var tmp$4 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
                header,
                /* :: */[
                  headerClassName,
                  /* [] */0
                ]
              ]);
          if (tmp$4 !== undefined) {
            tmp$3.className = Caml_option.valFromOption(tmp$4);
          }
          tmp$2 = React.createElement(FloatingHeader$GlazedWebsite.make, tmp$3);
        } else {
          var tmp$5 = {
            componentAtTheRight: headerComponentAtTheRight,
            useDarkNavBarLinks: useDarkNavBarLinks,
            currentPageIndex: currentPageIndex
          };
          var tmp$6 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
                header,
                /* :: */[
                  headerClassName,
                  /* [] */0
                ]
              ]);
          if (tmp$6 !== undefined) {
            tmp$5.className = Caml_option.valFromOption(tmp$6);
          }
          if (headerStyle !== undefined) {
            tmp$5.style = Caml_option.valFromOption(headerStyle);
          }
          if (onHeaderResize !== undefined) {
            tmp$5.onResize = Caml_option.valFromOption(onHeaderResize);
          }
          tmp$2 = React.createElement(Header$GlazedWebsite.make, tmp$5);
        }
        return React.createElement("div", tmp, tmp$2, children, React.createElement(MobileFooter$GlazedWebsite.make, {
                        currentPageIndex: currentPageIndex,
                        componentAtTheRight: footerComponentAtTheRight
                      }));
      }));

var $$default = make;

export {
  Styles ,
  make ,
  $$default ,
  $$default as default,
  
}
/* wrapper Not a pure module */
