// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Css_Core from "bs-css/lib/es6/src/Css_Core.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Belt_MapString from "bs-platform/lib/es6/belt_MapString.js";
import * as Form$GlazedWebsite from "./shared/forms/Form.js";
import * as Input$GlazedWebsite from "./shared/inputs/Input.js";
import * as Theme$GlazedWebsite from "./Theme.js";
import * as Utils$GlazedWebsite from "./shared/Utils.js";
import * as Button$GlazedWebsite from "./shared/Button.js";
import * as FormUtils$GlazedWebsite from "./shared/forms/FormUtils.js";
import * as FormRenderer$GlazedWebsite from "./shared/forms/FormRenderer.js";
import * as FormValidations$GlazedWebsite from "./shared/forms/FormValidations.js";

var container = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* [] */0
      ]
    ]);

var title = Curry._1(Css.style, /* :: */[
      Css.color(Css.hex("ffffff")),
      /* :: */[
        Css.fontSize(Css.rem(1.125)),
        /* :: */[
          Css.marginBottom(Css.rem(2.0)),
          /* [] */0
        ]
      ]
    ]);

var subtitle = Curry._1(Css.style, /* :: */[
      Css.color(Css.hex("feffef")),
      /* :: */[
        Css.fontSize(Css.rem(0.75)),
        /* :: */[
          Css.opacity(0.5),
          /* :: */[
            Css.marginBottom(Css.rem(1)),
            /* [] */0
          ]
        ]
      ]
    ]);

var anchor = Curry._1(Css.style, /* :: */[
      Css.color(Css.hex("2560F2")),
      /* [] */0
    ]);

var fields = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* [] */0
      ]
    ]);

var fieldsContainer = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* [] */0
      ]
    ]);

var field = Curry._1(Css.style, /* :: */[
      Css.padding2(Css.rem(0.625), /* zero */-789508312),
      /* [] */0
    ]);

var button = Curry._1(Css.style, /* :: */[
      Css.marginTop(Css.rem(1.25)),
      /* :: */[
        Css.width(Css.rem(10.625)),
        /* [] */0
      ]
    ]);

var error = Curry._1(Css.style, /* :: */[
      Css.fontSize(Css.rem(0.6875)),
      /* :: */[
        Css.fontFamily(/* `custom */[
              1066567601,
              Theme$GlazedWebsite.Fonts.heading
            ]),
        /* :: */[
          Css.color(Css.hex(Theme$GlazedWebsite.Colors.orange)),
          /* :: */[
            Css.opacity(0),
            /* :: */[
              Css.paddingTop(Css.rem(0.625)),
              /* :: */[
                Css.height(Css.rem(1.5625)),
                /* :: */[
                  Css.visibility(/* hidden */-862584982),
                  /* :: */[
                    Css.transitions(/* :: */[
                          Css_Core.Transition.shorthand(0, 500, undefined, "visibility"),
                          /* :: */[
                            Css_Core.Transition.shorthand(500, undefined, undefined, "opacity"),
                            /* [] */0
                          ]
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var errorVisible = Curry._1(Css.style, /* :: */[
      Css.opacity(1),
      /* :: */[
        Css.visibility(/* visible */589592690),
        /* :: */[
          Css.transitions(/* :: */[
                Css_Core.Transition.shorthand(100, undefined, undefined, "opacity"),
                /* [] */0
              ]),
          /* [] */0
        ]
      ]
    ]);

var Styles = {
  container: container,
  title: title,
  subtitle: subtitle,
  anchor: anchor,
  fields: fields,
  fieldsContainer: fieldsContainer,
  field: field,
  button: button,
  error: error,
  errorVisible: errorVisible
};

var fields$1 = Belt_MapString.fromArray([
      /* tuple */[
        "name",
        {
          schema: {
            validators: /* :: */[
              FormValidations$GlazedWebsite.Validators.required,
              /* [] */0
            ]
          },
          label: "your name",
          placeholder: "Name"
        }
      ],
      /* tuple */[
        "email",
        {
          schema: {
            validators: /* :: */[
              FormValidations$GlazedWebsite.Validators.required,
              /* :: */[
                (function (param, param$1, param$2) {
                    return FormValidations$GlazedWebsite.Validators.createRegexValidator(Utils$GlazedWebsite.Regex.email, param, param$1, param$2);
                  }),
                /* [] */0
              ]
            ]
          },
          label: "your email",
          placeholder: "Email"
        }
      ],
      /* tuple */[
        "message",
        {
          schema: {
            validators: /* :: */[
              FormValidations$GlazedWebsite.Validators.required,
              /* [] */0
            ]
          },
          label: "short description",
          placeholder: "Short Description"
        }
      ],
      /* tuple */[
        "techstack",
        {
          schema: {
            validators: /* :: */[
              FormValidations$GlazedWebsite.Validators.required,
              /* [] */0
            ]
          },
          label: "tech stack",
          placeholder: "Tech Stack"
        }
      ],
      /* tuple */[
        "role",
        {
          schema: {
            validators: /* :: */[
              FormValidations$GlazedWebsite.Validators.required,
              /* [] */0
            ]
          },
          label: "role",
          placeholder: "Role"
        }
      ]
    ]);

var schema = FormValidations$GlazedWebsite.Schema.fromArray(Belt_Array.map(Belt_MapString.toArray(fields$1), (function (param) {
            return /* tuple */[
                    param[0],
                    param[1].schema
                  ];
          })));

function getPropsForInput(propsForField) {
  var onBlur = propsForField.onBlur;
  var onFocus = propsForField.onFocus;
  var onChange = propsForField.onChange;
  var error = propsForField.error;
  var name = propsForField.name;
  var error$1;
  if (error !== undefined) {
    var match = error;
    error$1 = typeof match === "number" ? (
        match !== 0 ? "invalid email" : "required field"
      ) : "unexpected error";
  } else {
    error$1 = undefined;
  }
  var onChange$1 = function (param) {
    return FormUtils$GlazedWebsite.wrapOnChange(onChange, param);
  };
  var onFocus$1 = function (param) {
    return FormUtils$GlazedWebsite.wrapOnFocus(onFocus, param);
  };
  var onBlur$1 = function (param) {
    return FormUtils$GlazedWebsite.wrapOnBlur(onBlur, param);
  };
  var match$1 = Belt_MapString.get(fields$1, name);
  var match$2;
  if (match$1 !== undefined) {
    var field = match$1;
    match$2 = /* tuple */[
      field.label,
      field.placeholder
    ];
  } else {
    match$2 = /* tuple */[
      "label",
      undefined
    ];
  }
  return {
          name: name,
          label: match$2[0],
          placeholder: match$2[1],
          value: propsForField.value,
          error: error$1,
          onChange: onChange$1,
          onFocus: onFocus$1,
          onBlur: onBlur$1
        };
}

function JobOfferForm(Props) {
  var className = Props.className;
  var onSubmit = Props.onSubmit;
  var onChange = Props.onChange;
  var submissionStatus = Props.submissionStatus;
  var hackyOverrideOpt = Props.hackyOverride;
  var hackyOverride = hackyOverrideOpt !== undefined ? hackyOverrideOpt : false;
  var controlProps = Form$GlazedWebsite.useController(schema, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, onChange, undefined, undefined, onSubmit, /* () */0);
  var formRenderer = FormRenderer$GlazedWebsite.useRenderer(undefined, controlProps, /* () */0);
  var tmp = {
    noValidate: true,
    onSubmit: formRenderer.onSubmit
  };
  var tmp$1 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        container,
        /* :: */[
          className,
          /* [] */0
        ]
      ]);
  if (tmp$1 !== undefined) {
    tmp.className = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("form", tmp, React.createElement("div", {
                  className: fieldsContainer
                }, React.createElement("div", {
                      className: fields
                    }, Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.map(Curry._1(formRenderer.getPropsForField, "name"), getPropsForInput), (function (param) {
                                var name = param.name;
                                var tmp = {
                                  className: field,
                                  name: name,
                                  label: param.label,
                                  onChange: param.onChange,
                                  onFocus: param.onFocus,
                                  onBlur: param.onBlur,
                                  key: name
                                };
                                if (param.placeholder !== undefined) {
                                  tmp.placeholder = Caml_option.valFromOption(param.placeholder);
                                }
                                if (param.error !== undefined) {
                                  tmp.error = Caml_option.valFromOption(param.error);
                                }
                                if (param.value !== undefined) {
                                  tmp.value = Caml_option.valFromOption(param.value);
                                }
                                return React.createElement(Input$GlazedWebsite.make, tmp);
                              })), null), Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.map(Curry._1(formRenderer.getPropsForField, "email"), getPropsForInput), (function (param) {
                                var name = param.name;
                                var tmp = {
                                  className: field,
                                  name: name,
                                  label: param.label,
                                  onChange: param.onChange,
                                  onFocus: param.onFocus,
                                  onBlur: param.onBlur,
                                  key: name
                                };
                                if (param.placeholder !== undefined) {
                                  tmp.placeholder = Caml_option.valFromOption(param.placeholder);
                                }
                                if (param.error !== undefined) {
                                  tmp.error = Caml_option.valFromOption(param.error);
                                }
                                if (param.value !== undefined) {
                                  tmp.value = Caml_option.valFromOption(param.value);
                                }
                                return React.createElement(Input$GlazedWebsite.make, tmp);
                              })), null), Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.map(Curry._1(formRenderer.getPropsForField, "message"), getPropsForInput), (function (param) {
                                var name = param.name;
                                var tmp = {
                                  className: field,
                                  name: name,
                                  label: hackyOverride ? "your project / thesis's description" : param.label,
                                  onChange: param.onChange,
                                  onFocus: param.onFocus,
                                  onBlur: param.onBlur,
                                  key: name
                                };
                                if (param.placeholder !== undefined) {
                                  tmp.placeholder = Caml_option.valFromOption(param.placeholder);
                                }
                                if (param.error !== undefined) {
                                  tmp.error = Caml_option.valFromOption(param.error);
                                }
                                if (param.value !== undefined) {
                                  tmp.value = Caml_option.valFromOption(param.value);
                                }
                                return React.createElement(Input$GlazedWebsite.make, tmp);
                              })), null), Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.map(Curry._1(formRenderer.getPropsForField, "techstack"), getPropsForInput), (function (param) {
                                var name = param.name;
                                var tmp = {
                                  className: field,
                                  name: name,
                                  label: param.label,
                                  onChange: param.onChange,
                                  onFocus: param.onFocus,
                                  onBlur: param.onBlur,
                                  key: name
                                };
                                if (param.placeholder !== undefined) {
                                  tmp.placeholder = Caml_option.valFromOption(param.placeholder);
                                }
                                if (param.error !== undefined) {
                                  tmp.error = Caml_option.valFromOption(param.error);
                                }
                                if (param.value !== undefined) {
                                  tmp.value = Caml_option.valFromOption(param.value);
                                }
                                return React.createElement(Input$GlazedWebsite.make, tmp);
                              })), null), Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.map(Curry._1(formRenderer.getPropsForField, "role"), getPropsForInput), (function (param) {
                                var name = param.name;
                                var tmp = {
                                  className: field,
                                  name: name,
                                  label: param.label,
                                  onChange: param.onChange,
                                  onFocus: param.onFocus,
                                  onBlur: param.onBlur,
                                  key: name
                                };
                                if (param.placeholder !== undefined) {
                                  tmp.placeholder = Caml_option.valFromOption(param.placeholder);
                                }
                                if (param.error !== undefined) {
                                  tmp.error = Caml_option.valFromOption(param.error);
                                }
                                if (param.value !== undefined) {
                                  tmp.value = Caml_option.valFromOption(param.value);
                                }
                                return React.createElement(Input$GlazedWebsite.make, tmp);
                              })), null))), React.createElement(Button$GlazedWebsite.make, {
                  children: "> send",
                  type: /* Primary */0,
                  isDisabled: submissionStatus === /* Pending */1 || controlProps.formValidationResult !== /* FormValid */0,
                  className: button,
                  type_: "submit"
                }), typeof submissionStatus === "number" ? React.createElement("div", {
                    className: error
                  }) : React.createElement("div", {
                    className: Curry._1(Css.merge, /* :: */[
                          error,
                          /* :: */[
                            errorVisible,
                            /* [] */0
                          ]
                        ])
                  }, "An unexpected error occurred"));
}

var make = JobOfferForm;

var $$default = JobOfferForm;

export {
  Styles ,
  fields$1 as fields,
  schema ,
  getPropsForInput ,
  make ,
  $$default ,
  $$default as default,
  
}
/* container Not a pure module */
