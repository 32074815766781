// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Belt_Id from "bs-platform/lib/es6/belt_Id.js";
import * as Belt_Map from "bs-platform/lib/es6/belt_Map.js";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

var cmp = Caml_obj.caml_compare;

var FieldNameCompare = Belt_Id.MakeComparable({
      cmp: cmp
    });

function InternalMap(Type) {
  var make = function (param) {
    return Belt_Map.make(FieldNameCompare);
  };
  var fromArray = function (param) {
    return Belt_Map.fromArray(param, FieldNameCompare);
  };
  return {
          make: make,
          fromArray: fromArray,
          toArray: Belt_Map.toArray,
          reduce: Belt_Map.reduce,
          map: Belt_Map.mapWithKey,
          set: Belt_Map.set,
          get: Belt_Map.get,
          has: Belt_Map.has
        };
}

function make(param) {
  return Belt_Map.make(FieldNameCompare);
}

function fromArray(param) {
  return Belt_Map.fromArray(param, FieldNameCompare);
}

var Schema = {
  make: make,
  fromArray: fromArray,
  toArray: Belt_Map.toArray,
  reduce: Belt_Map.reduce,
  map: Belt_Map.mapWithKey,
  set: Belt_Map.set,
  get: Belt_Map.get,
  has: Belt_Map.has
};

function make$1(param) {
  return Belt_Map.make(FieldNameCompare);
}

function fromArray$1(param) {
  return Belt_Map.fromArray(param, FieldNameCompare);
}

var Values = {
  make: make$1,
  fromArray: fromArray$1,
  toArray: Belt_Map.toArray,
  reduce: Belt_Map.reduce,
  map: Belt_Map.mapWithKey,
  set: Belt_Map.set,
  get: Belt_Map.get,
  has: Belt_Map.has
};

function make$2(param) {
  return Belt_Map.make(FieldNameCompare);
}

function fromArray$2(param) {
  return Belt_Map.fromArray(param, FieldNameCompare);
}

var Errors = {
  make: make$2,
  fromArray: fromArray$2,
  toArray: Belt_Map.toArray,
  reduce: Belt_Map.reduce,
  map: Belt_Map.mapWithKey,
  set: Belt_Map.set,
  get: Belt_Map.get,
  has: Belt_Map.has
};

function make$3(param) {
  return Belt_Map.make(FieldNameCompare);
}

function fromArray$3(param) {
  return Belt_Map.fromArray(param, FieldNameCompare);
}

var Touched = {
  make: make$3,
  fromArray: fromArray$3,
  toArray: Belt_Map.toArray,
  reduce: Belt_Map.reduce,
  map: Belt_Map.mapWithKey,
  set: Belt_Map.set,
  get: Belt_Map.get,
  has: Belt_Map.has
};

function make$4(param) {
  return Belt_Map.make(FieldNameCompare);
}

function fromArray$4(param) {
  return Belt_Map.fromArray(param, FieldNameCompare);
}

var Visited = {
  make: make$4,
  fromArray: fromArray$4,
  toArray: Belt_Map.toArray,
  reduce: Belt_Map.reduce,
  map: Belt_Map.mapWithKey,
  set: Belt_Map.set,
  get: Belt_Map.get,
  has: Belt_Map.has
};

function identity(_result, _value, _values) {
  return /* Valid */0;
}

function required(result, value, values) {
  if (value === "") {
    return /* Invalid */[/* MissingRequired */0];
  } else {
    return /* Valid */0;
  }
}

function createRegexValidator(regex, result, value, values) {
  if (regex.test(value)) {
    return /* Valid */0;
  } else {
    return /* Invalid */[/* FailedRegex */1];
  }
}

var Validators = {
  identity: identity,
  required: required,
  createRegexValidator: createRegexValidator
};

function validateField(field, value, values) {
  return Belt_List.reduce(field.validators, /* Valid */0, (function (result, validator) {
                return Curry._3(validator, result, value, values);
              }));
}

function validate(schema, values, touched) {
  return Belt_Map.reduce(schema, /* FormValid */0, (function (formValidationResult, fieldName, fieldSchema) {
                var wasFieldTouched = Belt_Map.has(touched, fieldName);
                var fieldValidationResult;
                if (wasFieldTouched) {
                  var match = Belt_Map.get(values, fieldName);
                  fieldValidationResult = match !== undefined ? validateField(fieldSchema, Caml_option.valFromOption(match), values) : /* Valid */0;
                } else {
                  fieldValidationResult = /* Valid */0;
                }
                if (formValidationResult) {
                  if (fieldValidationResult) {
                    return /* FormInvalid */[Belt_Map.set(formValidationResult[0], fieldName, fieldValidationResult[0])];
                  } else {
                    return formValidationResult;
                  }
                } else if (fieldValidationResult) {
                  return /* FormInvalid */[Belt_Map.fromArray([/* tuple */[
                                  fieldName,
                                  fieldValidationResult[0]
                                ]], FieldNameCompare)];
                } else {
                  return /* FormValid */0;
                }
              }));
}

export {
  FieldNameCompare ,
  InternalMap ,
  Schema ,
  Values ,
  Errors ,
  Touched ,
  Visited ,
  Validators ,
  validateField ,
  validate ,
  
}
/* FieldNameCompare Not a pure module */
