// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Router from "@reach/router";
import * as Routing$GlazedWebsite from "./shared/Routing.js";

function useOpenSayHelloModal(param) {
  var $$location = Router.useLocation();
  var currentPath = $$location.pathname;
  var currentFullPath = encodeURIComponent(Routing$GlazedWebsite.getFullPath($$location));
  var sayHelloModalUrl = "" + (String(currentPath) + ("?modal=say-hello&backTo=" + (String(currentFullPath) + "")));
  var openSayHelloModal = React.useCallback((function ($$event) {
          $$event.preventDefault();
          return Routing$GlazedWebsite.navigate({
                      preventDefaultScrollBehavior: true
                    }, undefined, sayHelloModalUrl);
        }), ([]));
  return /* tuple */[
          sayHelloModalUrl,
          openSayHelloModal
        ];
}

export {
  useOpenSayHelloModal ,
  
}
/* react Not a pure module */
