// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Css_Core from "bs-css/lib/es6/src/Css_Core.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Utils$GlazedWebsite from "../Utils.js";
import * as ScrollContainer$GlazedWebsite from "../scroll-container/ScrollContainer.js";
import * as InOutCSSTransition$GlazedWebsite from "../InOutCSSTransition.js";

var wrapper = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.left(/* zero */-789508312),
          /* :: */[
            Css.width(Css.pct(100)),
            /* :: */[
              Css.height(Css.pct(100)),
              /* :: */[
                Css.zIndex(1),
                /* :: */[
                  Css.display(/* flex */-1010954439),
                  /* :: */[
                    Css.flexDirection(/* column */-963948842),
                    /* :: */[
                      Css.overflow(/* hidden */-862584982),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var scrollContainer = Curry._1(Css.style, /* :: */[
      Css.height(Css.pct(100)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* column */-963948842),
          /* :: */[
            Css.backgroundColor(Css.rgba(74, 74, 74, 0.5)),
            /* [] */0
          ]
        ]
      ]
    ]);

var scrollContent = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flex3(1, 0, /* auto */-1065951377),
        /* :: */[
          Css.justifyContent(/* center */98248149),
          /* :: */[
            Css.alignItems(/* center */98248149),
            /* [] */0
          ]
        ]
      ]
    ]);

var wrapperBeforeAppearingAnimation = Curry._1(Css.style, /* :: */[
      Css.opacity(0),
      /* [] */0
    ]);

var contentBeforeAppearingAnimation = Curry._1(Css.style, /* :: */[
      Css.opacity(0),
      /* :: */[
        Css.transform(Css.translate3d(/* zero */-789508312, Css.rem(1), /* zero */-789508312)),
        /* [] */0
      ]
    ]);

var wrapperVisible = Curry._1(Css.style, /* :: */[
      Css.opacity(1),
      /* :: */[
        Css.transition(300, undefined, undefined, "opacity"),
        /* [] */0
      ]
    ]);

var contentVisible = Curry._1(Css.style, /* :: */[
      Css.opacity(1),
      /* :: */[
        Css.transform(Css.translate3d(/* zero */-789508312, /* zero */-789508312, /* zero */-789508312)),
        /* :: */[
          Css.transitions(/* :: */[
                Css_Core.Transition.shorthand(300, undefined, undefined, "transform"),
                /* :: */[
                  Css_Core.Transition.shorthand(300, undefined, undefined, "opacity"),
                  /* [] */0
                ]
              ]),
          /* [] */0
        ]
      ]
    ]);

var wrapperLeaving = Curry._1(Css.style, /* :: */[
      Css.opacity(0),
      /* :: */[
        Css.transition(300, undefined, undefined, "opacity"),
        /* [] */0
      ]
    ]);

var contentLeaving = Curry._1(Css.style, /* :: */[
      Css.opacity(0),
      /* :: */[
        Css.transform(Css.translate3d(/* zero */-789508312, Css.rem(1), /* zero */-789508312)),
        /* :: */[
          Css.transitions(/* :: */[
                Css_Core.Transition.shorthand(300, undefined, undefined, "transform"),
                /* :: */[
                  Css_Core.Transition.shorthand(300, undefined, undefined, "opacity"),
                  /* [] */0
                ]
              ]),
          /* [] */0
        ]
      ]
    ]);

var Styles = {
  wrapper: wrapper,
  scrollContainer: scrollContainer,
  scrollContent: scrollContent,
  wrapperBeforeAppearingAnimation: wrapperBeforeAppearingAnimation,
  contentBeforeAppearingAnimation: contentBeforeAppearingAnimation,
  wrapperVisible: wrapperVisible,
  contentVisible: contentVisible,
  wrapperLeaving: wrapperLeaving,
  contentLeaving: contentLeaving
};

function getAnimationClassNames(animationState) {
  switch (animationState) {
    case /* BeforeAppearing */0 :
        return /* tuple */[
                wrapperBeforeAppearingAnimation,
                contentBeforeAppearingAnimation
              ];
    case /* Visible */1 :
        return /* tuple */[
                wrapperVisible,
                contentVisible
              ];
    case /* Leaving */2 :
        return /* tuple */[
                wrapperLeaving,
                contentLeaving
              ];
    
  }
}

function BaseModal$Wrapper(Props) {
  var children = Props.children;
  var className = Props.className;
  var onClose = Props.onClose;
  var onClick = React.useCallback((function ($$event) {
          $$event.stopPropagation();
          return Curry._1(onClose, /* () */0);
        }), [onClose]);
  var tmp = {
    id: "wrapper",
    onClick: onClick
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement("div", tmp, children);
}

var Wrapper = {
  make: BaseModal$Wrapper
};

function BaseModal$Content(Props) {
  var children = Props.children;
  var className = Props.className;
  var onClick = React.useCallback((function ($$event) {
          $$event.stopPropagation();
          return /* () */0;
        }), ([]));
  var tmp = {
    role: "dialog",
    onClick: onClick
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement("div", tmp, children);
}

var Content = {
  make: BaseModal$Content
};

function BaseModal(Props) {
  var children = Props.children;
  Props.modalId;
  var onClose = Props.onClose;
  var className = Props.className;
  var scrollContainerClassName = Props.scrollContainerClassName;
  var contentWrapperClassName = Props.contentWrapperClassName;
  var contentClassName = Props.contentClassName;
  var _in = Props.in;
  var onExit = Props.onExit;
  var onExited = Props.onExited;
  var tmp = {
    children: (function (animationState) {
        var match = getAnimationClassNames(animationState);
        var tmp = {
          children: children
        };
        var tmp$1 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
              contentClassName,
              /* [] */0
            ]);
        if (tmp$1 !== undefined) {
          tmp.className = Caml_option.valFromOption(tmp$1);
        }
        var tmp$2 = {
          children: React.createElement(BaseModal$Content, tmp)
        };
        var tmp$3 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
              scrollContainer,
              /* :: */[
                match[1],
                /* :: */[
                  scrollContainerClassName,
                  /* [] */0
                ]
              ]
            ]);
        if (tmp$3 !== undefined) {
          tmp$2.className = Caml_option.valFromOption(tmp$3);
        }
        var tmp$4 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
              scrollContent,
              /* :: */[
                contentWrapperClassName,
                /* [] */0
              ]
            ]);
        if (tmp$4 !== undefined) {
          tmp$2.contentClassName = Caml_option.valFromOption(tmp$4);
        }
        var tmp$5 = {
          children: React.createElement(ScrollContainer$GlazedWebsite.make, tmp$2),
          onClose: onClose
        };
        var tmp$6 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
              wrapper,
              /* :: */[
                match[0],
                /* :: */[
                  className,
                  /* [] */0
                ]
              ]
            ]);
        if (tmp$6 !== undefined) {
          tmp$5.className = Caml_option.valFromOption(tmp$6);
        }
        return React.createElement(BaseModal$Wrapper, tmp$5);
      }),
    in: _in,
    onExited: onExited
  };
  if (onExit !== undefined) {
    tmp.onExit = Caml_option.valFromOption(onExit);
  }
  return React.createElement(InOutCSSTransition$GlazedWebsite.make, tmp);
}

var make = BaseModal;

var $$default = BaseModal;

export {
  Styles ,
  getAnimationClassNames ,
  Wrapper ,
  Content ,
  make ,
  $$default ,
  $$default as default,
  
}
/* wrapper Not a pure module */
