// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as ScrollContext$GlazedWebsite from "./ScrollContext.js";

function createUseScrollerAPI(scrollerType, param) {
  var scrollContext = React.useContext(ScrollContext$GlazedWebsite.context);
  if (scrollerType) {
    return scrollContext.closestScroller;
  } else {
    return scrollContext.rootScroller;
  }
}

function useRootScrollerAPI(param) {
  return createUseScrollerAPI(/* Root */0, param);
}

function useClosestScrollerAPI(param) {
  return createUseScrollerAPI(/* Closest */1, param);
}

function createUseScrollValues(scrollerType) {
  return (function (param) {
      var listenerRef = React.useRef(/* () */0);
      var scrollerAPI = createUseScrollerAPI(scrollerType, /* () */0);
      var getScrollValues = React.useCallback((function (param) {
              return {
                      position: Curry._1(scrollerAPI.getScrollPosition, /* () */0),
                      boundingRect: Curry._1(scrollerAPI.getBoundingRect, /* () */0)
                    };
            }), [scrollerAPI]);
      var match = React.useState((function () {
              return Curry._1(getScrollValues, /* () */0);
            }));
      var setState = match[1];
      var handleScroll = React.useCallback((function (param) {
              return Curry._1(setState, (function (_state) {
                            return Curry._1(getScrollValues, /* () */0);
                          }));
            }), /* tuple */[
            getScrollValues,
            setState
          ]);
      React.useEffect((function () {
              Curry._2(scrollerAPI.registerScrollListener, listenerRef, handleScroll);
              return (function (param) {
                        return Curry._1(scrollerAPI.unregisterScrollListener, listenerRef);
                      });
            }));
      return match[0];
    });
}

var useRootScrollValues = createUseScrollValues(/* Root */0);

var useClosestScrollValues = createUseScrollValues(/* Closest */1);

export {
  createUseScrollerAPI ,
  useRootScrollerAPI ,
  useClosestScrollerAPI ,
  createUseScrollValues ,
  useRootScrollValues ,
  useClosestScrollValues ,
  
}
/* useRootScrollValues Not a pure module */
