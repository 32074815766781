// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Utils$GlazedWebsite from "../Utils.js";
import * as CommonInputStyles$GlazedWebsite from "./CommonInputStyles.js";

function InputWrapper(Props) {
  var className = Props.className;
  var labelWrapperClassName = Props.labelWrapperClassName;
  var labelClassName = Props.labelClassName;
  var errorClassName = Props.errorClassName;
  var label = Props.label;
  var error = Props.error;
  var children = Props.children;
  var ownErrorClassName = error === undefined ? CommonInputStyles$GlazedWebsite.error : Curry._1(Css.merge, /* :: */[
          CommonInputStyles$GlazedWebsite.error,
          /* :: */[
            CommonInputStyles$GlazedWebsite.errorVisible,
            /* [] */0
          ]
        ]);
  var tmp = { };
  var tmp$1 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        CommonInputStyles$GlazedWebsite.wrapper,
        /* :: */[
          className,
          /* [] */0
        ]
      ]);
  if (tmp$1 !== undefined) {
    tmp.className = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = { };
  var tmp$3 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        CommonInputStyles$GlazedWebsite.labelWrapper,
        /* :: */[
          labelWrapperClassName,
          /* [] */0
        ]
      ]);
  if (tmp$3 !== undefined) {
    tmp$2.className = Caml_option.valFromOption(tmp$3);
  }
  var tmp$4 = { };
  var tmp$5 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        CommonInputStyles$GlazedWebsite.label,
        /* :: */[
          labelClassName,
          /* [] */0
        ]
      ]);
  if (tmp$5 !== undefined) {
    tmp$4.className = Caml_option.valFromOption(tmp$5);
  }
  var tmp$6 = { };
  var tmp$7 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        ownErrorClassName,
        /* :: */[
          errorClassName,
          /* [] */0
        ]
      ]);
  if (tmp$7 !== undefined) {
    tmp$6.className = Caml_option.valFromOption(tmp$7);
  }
  return React.createElement("label", tmp, React.createElement("div", tmp$2, React.createElement("div", tmp$4, "<input \"", React.createElement("span", {
                          className: CommonInputStyles$GlazedWebsite.labelInner
                        }, label), "\">"), React.createElement("div", tmp$6, error !== undefined ? React.createElement("p", undefined, error) : null)), children);
}

var make = InputWrapper;

var $$default = InputWrapper;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* Css Not a pure module */
