// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as FormUtils$GlazedWebsite from "./FormUtils.js";
import * as FormValidations$GlazedWebsite from "./FormValidations.js";

function useController(schema, initialValuesOpt, validateOnInitOpt, additionalErrors, matchOpt, $staropt$star, $staropt$star$1, formValidationResult, $staropt$star$2, $staropt$star$3, $staropt$star$4, $staropt$star$5, $staropt$star$6, baseOnSubmit, param) {
  var initialValues = initialValuesOpt !== undefined ? Caml_option.valFromOption(initialValuesOpt) : FormUtils$GlazedWebsite.getInitialValues(schema);
  var validateOnInit = validateOnInitOpt !== undefined ? validateOnInitOpt : false;
  var match = matchOpt !== undefined ? matchOpt : React.useState((function () {
            return initialValues;
          }));
  var setValues = match[1];
  var values = match[0];
  var match$1 = $staropt$star !== undefined ? $staropt$star : React.useState((function () {
            return FormUtils$GlazedWebsite.getInitialTouched(schema, validateOnInit);
          }));
  var setTouched = match$1[1];
  var touched = match$1[0];
  var match$2;
  if ($staropt$star$1 !== undefined) {
    match$2 = $staropt$star$1;
  } else {
    var partial_arg = FormValidations$GlazedWebsite.Visited.make;
    match$2 = React.useState((function () {
            return Curry._1(partial_arg, /* () */0);
          }));
  }
  var setVisited = match$2[1];
  var baseOnFieldTouch = $staropt$star$2 !== undefined ? $staropt$star$2 : (function (_eventMetadata) {
        return /* () */0;
      });
  var baseOnFieldVisit = $staropt$star$3 !== undefined ? $staropt$star$3 : (function (_eventMetadata) {
        return /* () */0;
      });
  var baseOnChange = $staropt$star$4 !== undefined ? $staropt$star$4 : (function (_values, _eventMetadata) {
        return /* () */0;
      });
  var onFieldBlur = $staropt$star$5 !== undefined ? $staropt$star$5 : (function (_eventMetadata) {
        return /* () */0;
      });
  var onFieldFocus = $staropt$star$6 !== undefined ? $staropt$star$6 : (function (_eventMetadata) {
        return /* () */0;
      });
  var onFieldTouch = React.useCallback((function (eventMetadata) {
          Curry._1(setTouched, (function (touched) {
                  return Curry._3(FormValidations$GlazedWebsite.Touched.set, touched, eventMetadata.fieldName, true);
                }));
          return Curry._1(baseOnFieldTouch, eventMetadata);
        }), /* tuple */[
        baseOnFieldTouch,
        setTouched
      ]);
  var onFieldVisit = React.useCallback((function (eventMetadata) {
          Curry._1(setVisited, (function (visited) {
                  return Curry._3(FormValidations$GlazedWebsite.Visited.set, visited, eventMetadata.fieldName, true);
                }));
          return Curry._1(baseOnFieldVisit, eventMetadata);
        }), /* tuple */[
        baseOnFieldVisit,
        setVisited
      ]);
  var onChange = React.useCallback((function (nextValues) {
          return (function (eventMetadata) {
              Curry._1(setValues, (function (_values) {
                      return nextValues;
                    }));
              return Curry._2(baseOnChange, nextValues, eventMetadata);
            });
        }), /* tuple */[
        baseOnChange,
        setValues
      ]);
  var onSubmit = React.useCallback((function (values) {
          var nextTouched = FormUtils$GlazedWebsite.getAllFieldsTouched(schema);
          var formValidationResult = FormValidations$GlazedWebsite.validate(schema, values, nextTouched);
          if (formValidationResult === /* FormValid */0) {
            Curry._1(baseOnSubmit, values);
          }
          return Curry._1(setTouched, (function (_touched) {
                        return nextTouched;
                      }));
        }), /* tuple */[
        baseOnSubmit,
        schema,
        setTouched
      ]);
  var formValidationResult$1 = React.useMemo((function () {
          if (formValidationResult !== undefined) {
            return formValidationResult;
          } else {
            return FormUtils$GlazedWebsite.getValidationResult(schema, values, touched, additionalErrors);
          }
        }), /* tuple */[
        formValidationResult,
        schema,
        values,
        touched,
        additionalErrors
      ]);
  return {
          schema: schema,
          values: values,
          formValidationResult: formValidationResult$1,
          touched: touched,
          visited: match$2[0],
          onChange: onChange,
          onSubmit: onSubmit,
          onFieldBlur: onFieldBlur,
          onFieldFocus: onFieldFocus,
          onFieldTouch: onFieldTouch,
          onFieldVisit: onFieldVisit,
          setTouched: setTouched,
          setVisited: setVisited
        };
}

export {
  useController ,
  
}
/* react Not a pure module */
