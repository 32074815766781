// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Theme$GlazedWebsite from "./Theme.js";
import * as Utils$GlazedWebsite from "./shared/Utils.js";

var height = Css.rem(3.75);

var Variables = {
  height: height
};

var wrapper = Curry._1(Css.style, /* :: */[
      Css.backgroundColor(Css.hex(Theme$GlazedWebsite.Colors.glazedBlueMidnight)),
      /* :: */[
        Css.height(height),
        /* [] */0
      ]
    ]);

var Styles = {
  Variables: Variables,
  wrapper: wrapper
};

function MobileFooterLayout(Props) {
  var className = Props.className;
  var children = Props.children;
  var tmp = { };
  var tmp$1 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        wrapper,
        /* :: */[
          className,
          /* [] */0
        ]
      ]);
  if (tmp$1 !== undefined) {
    tmp.className = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("footer", tmp, children);
}

var make = MobileFooterLayout;

var $$default = MobileFooterLayout;

export {
  Styles ,
  make ,
  $$default ,
  $$default as default,
  
}
/* height Not a pure module */
