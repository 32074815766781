// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as InputWrapper$GlazedWebsite from "./InputWrapper.js";
import * as CommonInputStyles$GlazedWebsite from "./CommonInputStyles.js";

function Input(Props) {
  var className = Props.className;
  var labelWrapperClassName = Props.labelWrapperClassName;
  var labelClassName = Props.labelClassName;
  var errorClassName = Props.errorClassName;
  var name = Props.name;
  var label = Props.label;
  var placeholder = Props.placeholder;
  var disabledOpt = Props.disabled;
  var error = Props.error;
  var value = Props.value;
  var onChange = Props.onChange;
  var onFocus = Props.onFocus;
  var onBlur = Props.onBlur;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var tmp = {
    className: CommonInputStyles$GlazedWebsite.input,
    disabled: disabled
  };
  if (name !== undefined) {
    tmp.name = Caml_option.valFromOption(name);
  }
  if (placeholder !== undefined) {
    tmp.placeholder = Caml_option.valFromOption(placeholder);
  }
  if (value !== undefined) {
    tmp.value = Caml_option.valFromOption(value);
  }
  if (onFocus !== undefined) {
    tmp.onFocus = Caml_option.valFromOption(onFocus);
  }
  if (onBlur !== undefined) {
    tmp.onBlur = Caml_option.valFromOption(onBlur);
  }
  if (onChange !== undefined) {
    tmp.onChange = Caml_option.valFromOption(onChange);
  }
  var tmp$1 = {
    label: label,
    children: React.createElement("input", tmp)
  };
  if (className !== undefined) {
    tmp$1.className = Caml_option.valFromOption(className);
  }
  if (labelWrapperClassName !== undefined) {
    tmp$1.labelWrapperClassName = Caml_option.valFromOption(labelWrapperClassName);
  }
  if (labelClassName !== undefined) {
    tmp$1.labelClassName = Caml_option.valFromOption(labelClassName);
  }
  if (errorClassName !== undefined) {
    tmp$1.errorClassName = Caml_option.valFromOption(errorClassName);
  }
  if (error !== undefined) {
    tmp$1.error = Caml_option.valFromOption(error);
  }
  return React.createElement(InputWrapper$GlazedWebsite.make, tmp$1);
}

var make = Input;

var $$default = Input;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
