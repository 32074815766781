// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Js_exn from "bs-platform/lib/es6/js_exn.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Caml_js_exceptions from "bs-platform/lib/es6/caml_js_exceptions.js";

var Link = { };

var StaticQuery = { };

var BackgroundImage = { };

var $$Image = { };

function getImageFluid(result, name) {
  var image = result[name];
  try {
    return Caml_option.some(image.childImageSharp.fluid);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn[0] === Js_exn.$$Error) {
      return ;
    } else {
      throw exn;
    }
  }
}

function getResponsiveImageFluid(result, images) {
  return Belt_Array.map(images, (function (param) {
                var mediaQuery = param[1];
                var image = result[param[0]];
                var fluidImage = image.childImageSharp.fluid;
                if (mediaQuery !== undefined) {
                  return Object.assign(Object.assign({ }, fluidImage), {
                              media: mediaQuery
                            });
                } else {
                  return fluidImage;
                }
              }));
}

export {
  Link ,
  StaticQuery ,
  BackgroundImage ,
  $$Image ,
  getImageFluid ,
  getResponsiveImageFluid ,
  
}
/* No side effect */
