// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Css_Core from "bs-css/lib/es6/src/Css_Core.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Theme$GlazedWebsite from "./Theme.js";
import * as Utils$GlazedWebsite from "./shared/Utils.js";
import * as NavBarLinks$GlazedWebsite from "./NavBarLinks.js";
import * as ScrollContainer$GlazedWebsite from "./shared/scroll-container/ScrollContainer.js";
import * as MobileFooterLayout$GlazedWebsite from "./MobileFooterLayout.js";

var height = Css.rem(3.75);

var Variables = {
  height: height
};

var wrapper = Curry._1(Css.style, /* :: */[
      Css.position(/* fixed */10615156),
      /* :: */[
        Css.bottom(/* zero */-789508312),
        /* :: */[
          Css.left(/* zero */-789508312),
          /* :: */[
            Css.right(/* zero */-789508312),
            /* :: */[
              Css.display(/* flex */-1010954439),
              /* :: */[
                Css.alignItems(/* center */98248149),
                /* :: */[
                  Css.whiteSpace(/* nowrap */867913355),
                  /* :: */[
                    Css.transform(/* `translateZ */[
                          106728780,
                          /* zero */-789508312
                        ]),
                    /* :: */[
                      Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                            Css.display(/* none */-922086728),
                            /* [] */0
                          ]),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var navBarLinksWrapper = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.overflow(/* hidden */-862584982),
        /* :: */[
          Css.flex3(1, 1, /* auto */-1065951377),
          /* :: */[
            Css.after(/* :: */[
                  Css.contentRule(/* `text */[
                        -856044371,
                        ""
                      ]),
                  /* :: */[
                    Css.position(/* absolute */-1013592457),
                    /* :: */[
                      Css.top(/* zero */-789508312),
                      /* :: */[
                        Css.right(/* zero */-789508312),
                        /* :: */[
                          Css.bottom(/* zero */-789508312),
                          /* :: */[
                            Css.left(/* zero */-789508312),
                            /* :: */[
                              Css.boxShadow(Css_Core.Shadow.box(undefined, undefined, Css.px(10), Css.px(10), true, Css.hex(Theme$GlazedWebsite.Colors.glazedBlueMidnight))),
                              /* :: */[
                                Css.pointerEvents(/* none */-922086728),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var navBarLinksScrollerContent = Curry._1(Css.style, /* :: */[
      Css.flexDirection(/* row */5693978),
      /* :: */[
        Css.fontFamily(/* `custom */[
              1066567601,
              Theme$GlazedWebsite.Fonts.heading
            ]),
        /* :: */[
          Css.fontSize(Css.rem(0.625)),
          /* [] */0
        ]
      ]
    ]);

var navBarLinks = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* :: */[
          Css.padding2(Css.rem(0), Css.rem(0.625)),
          /* :: */[
            Css.height(height),
            /* :: */[
              Css.opacity(0.8),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  Variables: Variables,
  wrapper: wrapper,
  navBarLinksWrapper: navBarLinksWrapper,
  navBarLinksScrollerContent: navBarLinksScrollerContent,
  navBarLinks: navBarLinks
};

function MobileFooter(Props) {
  var className = Props.className;
  var navBarLinksClassName = Props.navBarLinksClassName;
  var currentPageIndexOpt = Props.currentPageIndex;
  var componentAtTheRight = Props.componentAtTheRight;
  var currentPageIndex = currentPageIndexOpt !== undefined ? currentPageIndexOpt : 0;
  var tmp = {
    children: null
  };
  var tmp$1 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        wrapper,
        /* :: */[
          className,
          /* [] */0
        ]
      ]);
  if (tmp$1 !== undefined) {
    tmp.className = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = {
    currentPageIndex: currentPageIndex
  };
  var tmp$3 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        navBarLinks,
        /* :: */[
          navBarLinksClassName,
          /* [] */0
        ]
      ]);
  if (tmp$3 !== undefined) {
    tmp$2.className = Caml_option.valFromOption(tmp$3);
  }
  return React.createElement(MobileFooterLayout$GlazedWebsite.make, tmp, React.createElement("div", {
                  className: navBarLinksWrapper
                }, React.createElement(ScrollContainer$GlazedWebsite.make, {
                      children: React.createElement(NavBarLinks$GlazedWebsite.make, tmp$2),
                      contentClassName: navBarLinksScrollerContent
                    })), componentAtTheRight !== undefined ? Caml_option.valFromOption(componentAtTheRight) : null);
}

var make = MobileFooter;

var $$default = MobileFooter;

export {
  Styles ,
  make ,
  $$default ,
  $$default as default,
  
}
/* height Not a pure module */
