// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Gatsby from "gatsby";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Theme$GlazedWebsite from "./Theme.js";
import * as Utils$GlazedWebsite from "./shared/Utils.js";

var wrapper = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.pointerEvents(/* auto */-1065951377),
        /* [] */0
      ]
    ]);

var lightTheme = Curry._1(Css.style, /* :: */[
      Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
      /* [] */0
    ]);

var darkTheme = Curry._1(Css.style, /* :: */[
      Css.color(Css.hex(Theme$GlazedWebsite.Colors.darkGreyDarker)),
      /* [] */0
    ]);

var logoWrapper = Curry._1(Css.style, /* :: */[
      Css.flex3(0, 0, /* auto */-1065951377),
      /* [] */0
    ]);

var item = Curry._1(Css.style, /* :: */[
      Css.padding(Css.rem(0.25)),
      /* :: */[
        Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
              Css.padding(Css.rem(0.75)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var dimmed = Curry._1(Css.style, /* :: */[
      Css.opacity(0.6),
      /* [] */0
    ]);

var explore = Curry._1(Css.style, /* :: */[
      Css.display(/* none */-922086728),
      /* :: */[
        Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
              Css.display(/* flex */-1010954439),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var Styles = {
  wrapper: wrapper,
  lightTheme: lightTheme,
  darkTheme: darkTheme,
  logoWrapper: logoWrapper,
  item: item,
  dimmed: dimmed,
  explore: explore
};

var items = [
  /* tuple */[
    "/#case-studies",
    "case studies",
    true
  ],
  /* tuple */[
    "/#about",
    "about",
    true
  ],
  /* tuple */[
    "/#team",
    "team",
    true
  ],
  /* tuple */[
    "/#manifesto",
    "manifesto",
    true
  ],
  /* tuple */[
    "https://blog.glazedsolutions.com",
    "blog",
    false
  ]
];

function NavBarLinks(Props) {
  var className = Props.className;
  var useDarkNavBarLinksOpt = Props.useDarkNavBarLinks;
  var currentPageIndex = Props.currentPageIndex;
  var onNavBarLinkClick = Props.onNavBarLinkClick;
  var useDarkNavBarLinks = useDarkNavBarLinksOpt !== undefined ? useDarkNavBarLinksOpt : false;
  var contextualStyles = useDarkNavBarLinks ? darkTheme : lightTheme;
  var wrapperStyles = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        wrapper,
        /* :: */[
          className,
          /* :: */[
            contextualStyles,
            /* [] */0
          ]
        ]
      ]);
  var tmp = { };
  if (wrapperStyles !== undefined) {
    tmp.className = Caml_option.valFromOption(wrapperStyles);
  }
  return React.createElement("div", tmp, React.createElement("div", {
                  className: Curry._1(Css.merge, /* :: */[
                        dimmed,
                        /* :: */[
                          item,
                          /* :: */[
                            explore,
                            /* [] */0
                          ]
                        ]
                      ])
                }, "explore:"), $$Array.mapi((function (index, param) {
                    var label = param[1];
                    var link = param[0];
                    var isDimmed = index !== (currentPageIndex - 1 | 0);
                    var linkStyles = isDimmed ? Curry._1(Css.merge, /* :: */[
                            item,
                            /* :: */[
                              dimmed,
                              /* :: */[
                                contextualStyles,
                                /* [] */0
                              ]
                            ]
                          ]) : Curry._1(Css.merge, /* :: */[
                            item,
                            /* :: */[
                              contextualStyles,
                              /* [] */0
                            ]
                          ]);
                    var onClick;
                    if (onNavBarLinkClick !== undefined) {
                      var handler = onNavBarLinkClick;
                      onClick = (function ($$event) {
                          return Curry._2(handler, $$event, link);
                        });
                    } else {
                      onClick = undefined;
                    }
                    if (param[2]) {
                      var tmp = {
                        to: link,
                        className: linkStyles,
                        children: "// " + label,
                        replace: true,
                        key: String(index)
                      };
                      if (onClick !== undefined) {
                        tmp.onClick = Caml_option.valFromOption(onClick);
                      }
                      return React.createElement(Gatsby.Link, tmp);
                    } else {
                      return React.createElement("a", {
                                  className: linkStyles,
                                  href: link
                                }, "// " + label);
                    }
                  }), items));
}

var make = NavBarLinks;

var $$default = NavBarLinks;

export {
  Styles ,
  items ,
  make ,
  $$default ,
  $$default as default,
  
}
/* wrapper Not a pure module */
