// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Fetch from "bs-fetch/lib/es6/src/Fetch.js";
import * as React from "react";
import * as Js_exn from "bs-platform/lib/es6/js_exn.js";
import * as Gatsby from "gatsby";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Css_Core from "bs-css/lib/es6/src/Css_Core.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Router from "@reach/router";
import * as SVG$GlazedWebsite from "./shared/SVG.js";
import * as Theme$GlazedWebsite from "./Theme.js";
import * as Button$GlazedWebsite from "./shared/Button.js";
import * as Gatsby$GlazedWebsite from "./shared/Gatsby.js";
import * as Header$GlazedWebsite from "./Header.js";
import * as Heading$GlazedWebsite from "./Heading.js";
import * as Routing$GlazedWebsite from "./shared/Routing.js";
import * as BaseModal$GlazedWebsite from "./shared/modals/BaseModal.js";
import * as GatsbyBackgroundImage from "gatsby-background-image";
import * as ContactForm$GlazedWebsite from "./ContactForm.js";
import * as MobileFooter$GlazedWebsite from "./MobileFooter.js";
import * as FormValidations$GlazedWebsite from "./shared/forms/FormValidations.js";
import * as ScrollContainer$GlazedWebsite from "./shared/scroll-container/ScrollContainer.js";
import * as Icon_githubSvg from "static/images/icon_github.svg";
import * as Icon_twitterSvg from "static/images/icon_twitter.svg";
import * as Icon_facebookSvg from "static/images/icon_facebook.svg";
import * as Icon_linkedinSvg from "static/images/icon_linkedin.svg";
import * as Icon_back_arrowSvg from "static/images/icon_back_arrow.svg";
import * as LogoGlazedSmallSvg from "static/images/logo-glazed-small.svg";

var emailSubject = "Glazed Mail";

var wrapper = Curry._1(Css.style, /* :: */[
      Css.backgroundColor(Css.hex(Theme$GlazedWebsite.Colors.almostAlmostWhite)),
      /* [] */0
    ]);

var contentWrapper = Curry._1(Css.style, /* :: */[
      Css.alignItems(/* stretch */-162316795),
      /* [] */0
    ]);

var content = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.flex3(1, 0, /* auto */-1065951377),
          /* [] */0
        ]
      ]
    ]);

var header = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.right(/* zero */-789508312),
          /* :: */[
            Css.left(/* zero */-789508312),
            /* [] */0
          ]
        ]
      ]
    ]);

var closeButtonWrapper = Curry._1(Css.style, /* :: */[
      Css.display(/* none */-922086728),
      /* :: */[
        Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
              Css.display(/* flex */-1010954439),
              /* :: */[
                Css.pointerEvents(/* auto */-1065951377),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

var closeButton = Curry._1(Css.style, /* :: */[
      Css.opacity(0.3),
      /* :: */[
        Css.padding(/* zero */-789508312),
        /* :: */[
          Css_Core.SVG.fill(Css.hex(Theme$GlazedWebsite.Colors.darkGrey)),
          /* :: */[
            Css_Core.SVG.stroke(Css.hex(Theme$GlazedWebsite.Colors.darkGrey)),
            /* [] */0
          ]
        ]
      ]
    ]);

var contacts = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.flex3(1, 1, /* auto */-1065951377),
          /* :: */[
            Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                  Css.flexDirection(/* row */5693978),
                  /* :: */[
                    Css.paddingTop(Header$GlazedWebsite.Styles.Variables.height),
                    /* [] */0
                  ]
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var map = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flex3(0, 0, Css.rem(12.5)),
          /* :: */[
            Css.backgroundColor(Css.hex("dddddd")),
            /* :: */[
              Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                    Css.flex3(1, 1, Css.rem(0.000000001)),
                    /* :: */[
                      Css.paddingTop(Header$GlazedWebsite.Styles.Variables.height),
                      /* [] */0
                    ]
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var mapImage = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.right(/* zero */-789508312),
          /* :: */[
            Css.bottom(/* zero */-789508312),
            /* :: */[
              Css.left(/* zero */-789508312),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var mapPin = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.bottom(Css.rem(5)),
        /* :: */[
          Css.left(Css.pct(23)),
          /* :: */[
            Css.height(Css.px(30)),
            /* :: */[
              Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                    Css.bottom(Css.pct(30)),
                    /* :: */[
                      Css.left(Css.pct(51)),
                      /* :: */[
                        Css.height(Css.px(50)),
                        /* [] */0
                      ]
                    ]
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var form = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* column */-963948842),
          /* :: */[
            Css.padding3(Css.rem(2.8125), Css.rem(1.25), Css.rem(5.625)),
            /* :: */[
              Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                    Css.justifyContent(/* center */98248149),
                    /* :: */[
                      Css.padding4(Css.rem(3.75), Css.rem(3.75), Css.rem(3.75), Css.rem(5)),
                      /* :: */[
                        Css.flex3(0, 1, Css.pct(50)),
                        /* [] */0
                      ]
                    ]
                  ]),
              /* :: */[
                Css.media(Theme$GlazedWebsite.Breakpoints.desktop, /* :: */[
                      Css.flex3(0, 1, Css.pct(40)),
                      /* [] */0
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var heading = Curry._1(Css.style, /* :: */[
      Css.fontSize(/* `rem */[
            5691738,
            1.125
          ]),
      /* :: */[
        Css.color(Css.hex(Theme$GlazedWebsite.Colors.darkGreyDarker)),
        /* :: */[
          Css.opacity(0.9),
          /* :: */[
            Css.before(/* :: */[
                  Css.contentRule(/* `text */[
                        -856044371,
                        "// "
                      ]),
                  /* [] */0
                ]),
            /* :: */[
              Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                    Css.fontSize(/* `rem */[
                          5691738,
                          2
                        ]),
                    /* :: */[
                      Css.before(/* :: */[
                            Css.display(/* none */-922086728),
                            /* [] */0
                          ]),
                      /* [] */0
                    ]
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var hidden = Curry._1(Css.style, /* :: */[
      Css.opacity(0),
      /* :: */[
        Css.visibility(/* hidden */-862584982),
        /* :: */[
          Css.transitions(/* :: */[
                Css_Core.Transition.shorthand(0, 500, undefined, "visibility"),
                /* :: */[
                  Css_Core.Transition.shorthand(500, undefined, undefined, "opacity"),
                  /* [] */0
                ]
              ]),
          /* [] */0
        ]
      ]
    ]);

var visible = Curry._1(Css.style, /* :: */[
      Css.visibility(/* visible */589592690),
      /* :: */[
        Css.transitions(/* :: */[
              Css_Core.Transition.shorthand(100, undefined, undefined, "opacity"),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var formMessage = Curry._1(Css.merge, /* :: */[
      Curry._1(Css.style, /* :: */[
            Css.display(/* inline */423610969),
            /* :: */[
              Css.padding3(Css.rem(0.3125), /* zero */-789508312, Css.rem(0.625)),
              /* :: */[
                Css.fontSize(Css.rem(0.75)),
                /* :: */[
                  Css.lineHeight(/* `abs */[
                        4845682,
                        2
                      ]),
                  /* :: */[
                    Css.color(Css.hex(Theme$GlazedWebsite.Colors.darkGrey)),
                    /* :: */[
                      Css.opacity(0.6),
                      /* :: */[
                        Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                              Css.display(/* flex */-1010954439),
                              /* :: */[
                                Css.flexDirection(/* column */-963948842),
                                /* :: */[
                                  Css.padding3(Css.rem(1.5625), /* zero */-789508312, Css.rem(2.8126)),
                                  /* :: */[
                                    Css.fontSize(Css.rem(0.875)),
                                    /* :: */[
                                      Css.opacity(1),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ]),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]),
      /* :: */[
        visible,
        /* [] */0
      ]
    ]);

var formMessageHidden = Curry._1(Css.merge, /* :: */[
      formMessage,
      /* :: */[
        hidden,
        /* :: */[
          Curry._1(Css.style, /* :: */[
                Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                      Css.opacity(0),
                      /* [] */0
                    ]),
                /* [] */0
              ]),
          /* [] */0
        ]
      ]
    ]);

var fillFormMessage = Curry._1(Css.style, /* :: */[
      Css.display(/* inline */423610969),
      /* :: */[
        Css.paddingRight(Css.rem(0.3125)),
        /* [] */0
      ]
    ]);

var sendEmailParagraph = Curry._1(Css.style, /* :: */[
      Css.display(/* inline */423610969),
      /* :: */[
        Css.color(Css.hex(Theme$GlazedWebsite.Colors.grey)),
        /* [] */0
      ]
    ]);

var link = Curry._1(Css.style, /* :: */[
      Css.color(Css.hex(Theme$GlazedWebsite.Colors.glazedBlueDarkish)),
      /* [] */0
    ]);

var socialNetworks = Curry._1(Css.style, /* :: */[
      Css.display(/* none */-922086728),
      /* :: */[
        Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
              Css.position(/* absolute */-1013592457),
              /* :: */[
                Css.top(/* zero */-789508312),
                /* :: */[
                  Css.right(Css.rem(4.6875)),
                  /* :: */[
                    Css.margin(Css.rem(-0.9375)),
                    /* :: */[
                      Css.display(/* flex */-1010954439),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]),
        /* [] */0
      ]
    ]);

var socialIconLink = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.margin(Css.rem(0.9375)),
        /* [] */0
      ]
    ]);

var socialIcon = Curry._1(Css.style, /* :: */[
      Css.opacity(0.4),
      /* :: */[
        Css_Core.SVG.fill(Css.hex(Theme$GlazedWebsite.Colors.darkGrey)),
        /* [] */0
      ]
    ]);

var addressDetails = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(Css.rem(1.25)),
        /* :: */[
          Css.right(Css.rem(1.25)),
          /* :: */[
            Css.width(Css.rem(12.875)),
            /* :: */[
              Css.height(Css.rem(12.875)),
              /* :: */[
                Css.padding4(Css.rem(1.5625), Css.rem(2.5), Css.rem(1.5625), Css.rem(1.25)),
                /* :: */[
                  Css.backgroundColor(Css.hex(Theme$GlazedWebsite.Colors.white)),
                  /* :: */[
                    Css.fontSize(Css.rem(0.75)),
                    /* :: */[
                      Css.fontFamily(/* `custom */[
                            1066567601,
                            Theme$GlazedWebsite.Fonts.heading
                          ]),
                      /* :: */[
                        Css.lineHeight(/* `abs */[
                              4845682,
                              1.75
                            ]),
                        /* :: */[
                          Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                                Css.top(Css.rem(3.125)),
                                /* :: */[
                                  Css.right(Css.rem(3.125)),
                                  /* :: */[
                                    Css.width(Css.rem(18.125)),
                                    /* :: */[
                                      Css.height(Css.rem(18.125)),
                                      /* :: */[
                                        Css.padding4(Css.rem(3.125), Css.rem(2.5), Css.rem(3.4375), Css.rem(2.5)),
                                        /* :: */[
                                          Css.fontSize(Css.rem(0.84375)),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var address = Curry._1(Css.style, /* :: */[
      Css.whiteSpace(/* preLine */538440119),
      /* :: */[
        Css.color(Css.hex(Theme$GlazedWebsite.Colors.darkGrey)),
        /* [] */0
      ]
    ]);

var postalCode = Curry._1(Css.style, /* :: */[
      Css.padding3(Css.rem(1.5625), /* zero */-789508312, Css.rem(0.9375)),
      /* :: */[
        Css.color(Css.hex(Theme$GlazedWebsite.Colors.grey)),
        /* :: */[
          Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                Css.padding3(Css.rem(1.875), /* zero */-789508312, Css.rem(2.5)),
                /* [] */0
              ]),
          /* [] */0
        ]
      ]
    ]);

var openInMapsLink = Curry._1(Css.merge, /* :: */[
      link,
      /* :: */[
        Curry._1(Css.style, /* :: */[
              Css.fontSize(Css.rem(0.75)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var contactForm = Curry._1(Css.merge, /* :: */[
      visible,
      /* :: */[
        Curry._1(Css.style, /* :: */[
              Css.media(Theme$GlazedWebsite.Breakpoints.desktop, /* :: */[
                    Css.maxWidth(Css.rem(28.125)),
                    /* [] */0
                  ]),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var mobileCloseButton = Curry._1(Css.style, /* :: */[
      Css.padding(Css.rem(1.25)),
      /* :: */[
        Css.background(/* none */-922086728),
        /* :: */[
          Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
          /* :: */[
            Css.fontFamily(/* `custom */[
                  1066567601,
                  Theme$GlazedWebsite.Fonts.heading
                ]),
            /* :: */[
              Css.fontSize(Css.rem(0.625)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  wrapper: wrapper,
  contentWrapper: contentWrapper,
  content: content,
  header: header,
  closeButtonWrapper: closeButtonWrapper,
  closeButton: closeButton,
  contacts: contacts,
  map: map,
  mapImage: mapImage,
  mapPin: mapPin,
  form: form,
  heading: heading,
  hidden: hidden,
  visible: visible,
  formMessage: formMessage,
  formMessageHidden: formMessageHidden,
  fillFormMessage: fillFormMessage,
  sendEmailParagraph: sendEmailParagraph,
  link: link,
  socialNetworks: socialNetworks,
  socialIconLink: socialIconLink,
  socialIcon: socialIcon,
  addressDetails: addressDetails,
  address: address,
  postalCode: postalCode,
  openInMapsLink: openInMapsLink,
  contactForm: contactForm,
  mobileCloseButton: mobileCloseButton
};

var backgroundImageQuery = (Gatsby.graphql`
    query {
      map_mobile: file(relativePath: { eq: "map_mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      map_desktop: file(relativePath: { eq: "map.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

function SayHelloModal$CloseButton(Props) {
  var backToUrl = Props.backToUrl;
  var close = Props.close;
  return React.createElement(Gatsby.Link, {
              to: backToUrl,
              className: closeButtonWrapper,
              children: React.createElement(Button$GlazedWebsite.make, {
                    children: React.createElement(SVG$GlazedWebsite.make, {
                          height: "12",
                          asset: Icon_back_arrowSvg.default
                        }),
                    className: closeButton
                  }),
              onClick: close
            });
}

var CloseButton = {
  make: SayHelloModal$CloseButton
};

function SayHelloModal(Props) {
  var modalId = Props.modalId;
  var onClose = Props.onClose;
  var _inOpt = Props.in;
  var onExitedOpt = Props.onExited;
  var _in = _inOpt !== undefined ? _inOpt : true;
  var onExited = onExitedOpt !== undefined ? onExitedOpt : (function (param) {
        return /* () */0;
      });
  var $$location = Router.useLocation();
  var match = new URLSearchParams($$location.search).get("backTo");
  var backToUrl = (match == null) ? "/" : match;
  var onExited$1 = React.useCallback((function (param) {
          Curry._1(onExited, /* () */0);
          var match = new URLSearchParams($$location.search).get("modal");
          if ((match == null) || match !== "say-hello") {
            return /* () */0;
          } else {
            return Routing$GlazedWebsite.navigate({
                        preventDefaultScrollBehavior: true
                      }, undefined, backToUrl);
          }
        }), /* tuple */[
        onExited,
        backToUrl,
        $$location
      ]);
  var handleCloseButtonClick = React.useCallback((function ($$event) {
          $$event.preventDefault();
          return Curry._1(onClose, /* () */0);
        }), [onClose]);
  var onNavBarLinkClick = React.useCallback((function (_event) {
          return (function (link) {
              return Routing$GlazedWebsite.navigate(undefined, undefined, link);
            });
        }), ([]));
  var match$1 = React.useState((function () {
          return /* Pristine */0;
        }));
  var setSubmissionStatus = match$1[1];
  var submissionStatus = match$1[0];
  var onSubmit = React.useCallback((function (values) {
          Curry._1(setSubmissionStatus, (function (_status) {
                  return /* Pending */1;
                }));
          var valuesArray = Curry._1(FormValidations$GlazedWebsite.Values.toArray, values);
          var payload = Js_dict.fromList(Belt_List.map(Belt_List.add(Belt_List.fromArray(valuesArray), /* tuple */[
                        "subject",
                        emailSubject
                      ]), (function (param) {
                      return /* tuple */[
                              param[0],
                              param[1]
                            ];
                    })));
          fetch("/.netlify/functions/sendEmail", Fetch.RequestInit.make(/* Post */2, {
                              "Content-Type": "application/json"
                            }, Caml_option.some(JSON.stringify(payload)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(/* () */0)).then((function (response) {
                        if (response.status < 200 || response.status >= 300) {
                          return Promise.reject(Js_exn.raiseError(response.statusText));
                        } else {
                          return Promise.resolve(response);
                        }
                      })).then((function (prim) {
                      return prim.json();
                    })).then((function (_response) {
                    return Promise.resolve(Curry._1(setSubmissionStatus, (function (_status) {
                                      return /* Success */2;
                                    })));
                  })).catch((function (_error) {
                  return Promise.resolve(Curry._1(setSubmissionStatus, (function (_status) {
                                    return /* Failed */[/* Unexpected */1];
                                  })));
                }));
          return /* () */0;
        }), [setSubmissionStatus]);
  var onChange = React.useCallback((function (_values) {
          return (function (_eventMetadata) {
              if (typeof submissionStatus === "number") {
                return /* () */0;
              } else {
                return Curry._1(setSubmissionStatus, (function (_status) {
                              return /* Pristine */0;
                            }));
              }
            });
        }), /* tuple */[
        submissionStatus,
        setSubmissionStatus
      ]);
  var match$2 = submissionStatus === /* Success */2 ? /* tuple */[
      formMessageHidden,
      Curry._1(Css.merge, /* :: */[
            contactForm,
            /* :: */[
              hidden,
              /* [] */0
            ]
          ]),
      "we'll get in touch soon!"
    ] : /* tuple */[
      formMessage,
      contactForm,
      "say hello"
    ];
  var queryResult = Gatsby.useStaticQuery(backgroundImageQuery);
  var fluidMapImage = Gatsby$GlazedWebsite.getResponsiveImageFluid(queryResult, [
        /* tuple */[
          "map_mobile",
          undefined
        ],
        /* tuple */[
          "map_desktop",
          "(min-width: 1024px)"
        ]
      ]);
  return React.createElement(BaseModal$GlazedWebsite.make, {
              children: null,
              modalId: modalId,
              onClose: onClose,
              scrollContainerClassName: wrapper,
              contentWrapperClassName: contentWrapper,
              contentClassName: content,
              in: _in,
              onExited: onExited$1
            }, React.createElement(ScrollContainer$GlazedWebsite.make, {
                  children: React.createElement("div", {
                        className: contacts
                      }, React.createElement("div", {
                            className: map
                          }, React.createElement(GatsbyBackgroundImage.default, {
                                fluid: fluidMapImage,
                                className: mapImage,
                                style: {
                                  position: "absolute"
                                }
                              }), React.createElement(SVG$GlazedWebsite.make, {
                                className: mapPin,
                                asset: LogoGlazedSmallSvg.default
                              }), React.createElement("div", {
                                className: addressDetails
                              }, React.createElement("p", {
                                    className: address
                                  }, "Rua Mouzinho de Albuquerque\n nº 744, 3º andar"), React.createElement("p", {
                                    className: postalCode
                                  }, "4450-203  Matosinhos, PT"), React.createElement("a", {
                                    className: openInMapsLink,
                                    href: "https://goo.gl/maps/V6Q8fQyzHStX6wGx7",
                                    rel: "nofollow",
                                    target: "_blank"
                                  }, "> open in google maps"))), React.createElement("div", {
                            className: form
                          }, React.createElement("div", {
                                className: socialNetworks
                              }, React.createElement("a", {
                                    className: socialIconLink,
                                    href: "https://twitter.com/glazedSolutions",
                                    rel: "nofollow",
                                    target: "_blank"
                                  }, React.createElement(SVG$GlazedWebsite.make, {
                                        className: socialIcon,
                                        height: "14",
                                        asset: Icon_twitterSvg.default
                                      })), React.createElement("a", {
                                    className: socialIconLink,
                                    href: "https://www.facebook.com/glazedEliteDevelopers/",
                                    rel: "nofollow",
                                    target: "_blank"
                                  }, React.createElement(SVG$GlazedWebsite.make, {
                                        className: socialIcon,
                                        height: "14",
                                        asset: Icon_facebookSvg.default
                                      })), React.createElement("a", {
                                    className: socialIconLink,
                                    href: "https://www.linkedin.com/company/glazed-solutions/",
                                    rel: "nofollow",
                                    target: "_blank"
                                  }, React.createElement(SVG$GlazedWebsite.make, {
                                        className: socialIcon,
                                        height: "14",
                                        asset: Icon_linkedinSvg.default
                                      })), React.createElement("a", {
                                    className: socialIconLink,
                                    href: "https://github.com/glazed-elite-developers",
                                    rel: "nofollow",
                                    target: "_blank"
                                  }, React.createElement(SVG$GlazedWebsite.make, {
                                        className: socialIcon,
                                        height: "14",
                                        asset: Icon_githubSvg.default
                                      }))), React.createElement(Heading$GlazedWebsite.make, {
                                level: /* H1 */0,
                                className: heading,
                                children: match$2[2]
                              }), React.createElement("div", {
                                className: match$2[0]
                              }, React.createElement("p", {
                                    className: fillFormMessage
                                  }, "Fill the form, or, if you prefer,"), React.createElement("p", {
                                    className: sendEmailParagraph
                                  }, "<a>", React.createElement("a", {
                                        className: link,
                                        href: "mailto:info@glazedsolutions.com"
                                      }, "send us an email"), "</a>")), React.createElement(ContactForm$GlazedWebsite.make, {
                                className: match$2[1],
                                onSubmit: onSubmit,
                                onChange: onChange,
                                submissionStatus: submissionStatus
                              })))
                }), React.createElement(Header$GlazedWebsite.make, {
                  className: header,
                  componentAtTheRight: React.createElement(SayHelloModal$CloseButton, {
                        backToUrl: backToUrl,
                        close: handleCloseButtonClick
                      }),
                  onNavBarLinkClick: onNavBarLinkClick,
                  useDarkNavBarLinks: true
                }), React.createElement(MobileFooter$GlazedWebsite.make, {
                  componentAtTheRight: React.createElement(Gatsby.Link, {
                        to: backToUrl,
                        children: React.createElement(Button$GlazedWebsite.make, {
                              children: "< back team",
                              className: mobileCloseButton
                            }),
                        onClick: handleCloseButtonClick
                      })
                }));
}

var make = SayHelloModal;

var $$default = SayHelloModal;

export {
  emailSubject ,
  Styles ,
  backgroundImageQuery ,
  CloseButton ,
  make ,
  $$default ,
  $$default as default,
  
}
/* wrapper Not a pure module */
