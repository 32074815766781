// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Theme$GlazedWebsite from "./Theme.js";
import * as Utils$GlazedWebsite from "./shared/Utils.js";

var commonStyles = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.fontWeight(/* `num */[
              5496390,
              700
            ]),
        /* :: */[
          Css.fontFamily(/* `custom */[
                1066567601,
                Theme$GlazedWebsite.Fonts.heading
              ]),
          /* [] */0
        ]
      ]
    ]);

var h1 = Curry._1(Css.merge, /* :: */[
      commonStyles,
      /* :: */[
        Curry._1(Css.style, /* :: */[
              Css.fontSize(Css.rem(1.5)),
              /* :: */[
                Css.lineHeight(Css.rem(2.125)),
                /* :: */[
                  Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                        Css.fontSize(Css.rem(2.25)),
                        /* :: */[
                          Css.lineHeight(Css.rem(2.75)),
                          /* [] */0
                        ]
                      ]),
                  /* [] */0
                ]
              ]
            ]),
        /* [] */0
      ]
    ]);

var h2 = Curry._1(Css.merge, /* :: */[
      commonStyles,
      /* :: */[
        Curry._1(Css.style, /* :: */[
              Css.fontSize(Css.rem(1.125)),
              /* :: */[
                Css.color(Css.hex(Theme$GlazedWebsite.Colors.grey)),
                /* :: */[
                  Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                        Css.fontSize(Css.rem(2)),
                        /* [] */0
                      ]),
                  /* [] */0
                ]
              ]
            ]),
        /* [] */0
      ]
    ]);

var h3 = Curry._1(Css.merge, /* :: */[
      commonStyles,
      /* :: */[
        Curry._1(Css.style, /* :: */[
              Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                    Css.fontSize(Css.rem(1.5)),
                    /* :: */[
                      Css.lineHeight(Css.rem(2.75)),
                      /* [] */0
                    ]
                  ]),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var h4 = Curry._1(Css.merge, /* :: */[
      commonStyles,
      /* :: */[
        Curry._1(Css.style, /* [] */0),
        /* [] */0
      ]
    ]);

var h5 = Curry._1(Css.merge, /* :: */[
      commonStyles,
      /* :: */[
        Curry._1(Css.style, /* [] */0),
        /* [] */0
      ]
    ]);

var h6 = Curry._1(Css.merge, /* :: */[
      commonStyles,
      /* :: */[
        Curry._1(Css.style, /* [] */0),
        /* [] */0
      ]
    ]);

var Styles = {
  commonStyles: commonStyles,
  h1: h1,
  h2: h2,
  h3: h3,
  h4: h4,
  h5: h5,
  h6: h6
};

function Heading(Props) {
  var level = Props.level;
  var className = Props.className;
  var children = Props.children;
  var match;
  switch (level) {
    case /* H1 */0 :
        match = /* tuple */[
          "h1",
          h1
        ];
        break;
    case /* H2 */1 :
        match = /* tuple */[
          "h2",
          h2
        ];
        break;
    case /* H3 */2 :
        match = /* tuple */[
          "h3",
          h3
        ];
        break;
    case /* H4 */3 :
        match = /* tuple */[
          "h4",
          h4
        ];
        break;
    case /* H5 */4 :
        match = /* tuple */[
          "h5",
          h5
        ];
        break;
    case /* H6 */5 :
        match = /* tuple */[
          "h6",
          h6
        ];
        break;
    
  }
  var tmp = { };
  var tmp$1 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        match[1],
        /* :: */[
          className,
          /* [] */0
        ]
      ]);
  if (tmp$1 !== undefined) {
    tmp.className = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(match[0], tmp, children);
}

var make = Heading;

var $$default = Heading;

export {
  Styles ,
  make ,
  $$default ,
  $$default as default,
  
}
/* commonStyles Not a pure module */
