// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Css_Core from "bs-css/lib/es6/src/Css_Core.js";
import * as Theme$GlazedWebsite from "../../Theme.js";

var wrapper = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* [] */0
      ]
    ]);

var labelWrapper = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* :: */[
          Css.justifyContent(/* spaceBetween */516682146),
          /* [] */0
        ]
      ]
    ]);

var label = Curry._1(Css.style, /* :: */[
      Css.color(Css.hex("9FA8B3")),
      /* :: */[
        Css.fontSize(Css.rem(0.625)),
        /* :: */[
          Css.fontWeight(/* `num */[
                5496390,
                700
              ]),
          /* :: */[
            Css.lineHeight(Css.px(23)),
            /* :: */[
              Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                    Css.fontSize(Css.rem(0.6875)),
                    /* [] */0
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var labelInner = Curry._1(Css.style, /* :: */[
      Css.color(Css.hex("000")),
      /* [] */0
    ]);

var error = Curry._1(Css.style, /* :: */[
      Css.fontSize(Css.rem(0.6875)),
      /* :: */[
        Css.fontFamily(/* `custom */[
              1066567601,
              Theme$GlazedWebsite.Fonts.heading
            ]),
        /* :: */[
          Css.color(Css.hex(Theme$GlazedWebsite.Colors.orange)),
          /* :: */[
            Css.opacity(0),
            /* :: */[
              Css.visibility(/* hidden */-862584982),
              /* :: */[
                Css.transitions(/* :: */[
                      Css_Core.Transition.shorthand(0, 500, undefined, "visibility"),
                      /* :: */[
                        Css_Core.Transition.shorthand(500, undefined, undefined, "opacity"),
                        /* [] */0
                      ]
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var errorVisible = Curry._1(Css.style, /* :: */[
      Css.opacity(1),
      /* :: */[
        Css.visibility(/* visible */589592690),
        /* :: */[
          Css.transitions(/* :: */[
                Css_Core.Transition.shorthand(100, undefined, undefined, "opacity"),
                /* [] */0
              ]),
          /* [] */0
        ]
      ]
    ]);

var input = Curry._1(Css.style, /* :: */[
      Css.backgroundColor(Css.hex("F4F6FB")),
      /* :: */[
        Css.transition(300, undefined, undefined, "borderWidth"),
        /* :: */[
          Css.borderWidth(Css.px(0)),
          /* :: */[
            Css.color(Css.hex("9FA8B3")),
            /* :: */[
              Css.display(/* block */888960333),
              /* :: */[
                Css.fontFamily(/* `custom */[
                      1066567601,
                      Theme$GlazedWebsite.Fonts.heading
                    ]),
                /* :: */[
                  Css.lineHeight(Css.rem(1.44)),
                  /* :: */[
                    Css.marginTop(Css.rem(0.625)),
                    /* :: */[
                      Css.padding2(Css.rem(0.93), Css.rem(1.25)),
                      /* :: */[
                        Css.fontSize(Css.rem(0.75)),
                        /* :: */[
                          Css.borderBottomWidth(Css.px(1)),
                          /* :: */[
                            Css.borderColor(/* transparent */582626130),
                            /* :: */[
                              Css.borderRadius(/* zero */-789508312),
                              /* :: */[
                                Css.resize(/* none */-922086728),
                                /* :: */[
                                  Css.hover(/* :: */[
                                        Css.borderColor(Css.hex("2962F6")),
                                        /* :: */[
                                          Css.borderBottomWidth(Css.px(1)),
                                          /* [] */0
                                        ]
                                      ]),
                                  /* :: */[
                                    Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                                          Css.fontSize(Css.rem(0.93)),
                                          /* [] */0
                                        ]),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

export {
  wrapper ,
  labelWrapper ,
  label ,
  labelInner ,
  error ,
  errorVisible ,
  input ,
  
}
/* wrapper Not a pure module */
