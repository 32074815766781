// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Gatsby from "gatsby";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as SVG$GlazedWebsite from "./shared/SVG.js";
import * as Theme$GlazedWebsite from "./Theme.js";
import * as Utils$GlazedWebsite from "./shared/Utils.js";
import * as NavBarLinks$GlazedWebsite from "./NavBarLinks.js";
import * as LogoGlazedSvg from "static/images/logo-glazed.svg";
import * as ResizeMonitorContainer$GlazedWebsite from "./shared/resize-monitor/ResizeMonitorContainer.js";
import * as LogoGlazedOutlineSvg from "static/images/logo-glazed-outline.svg";

var height = Css.px(117);

var Variables = {
  height: height
};

var nav = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* :: */[
          Css.pointerEvents(/* none */-922086728),
          /* :: */[
            Css.padding2(Css.rem(1.25), Css.rem(1.25)),
            /* :: */[
              Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                    Css.padding2(Css.rem(2.1875), Css.rem(4.6875)),
                    /* :: */[
                      Css.height(height),
                      /* [] */0
                    ]
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var logoWrapper = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flex3(0, 0, /* auto */-1065951377),
        /* :: */[
          Css.width(Css.px(122)),
          /* :: */[
            Css.pointerEvents(/* auto */-1065951377),
            /* [] */0
          ]
        ]
      ]
    ]);

var logo = Curry._1(Css.style, /* :: */[
      Css.height(Css.rem(2.1875)),
      /* [] */0
    ]);

var navBarLinks = Curry._1(Css.style, /* :: */[
      Css.display(/* none */-922086728),
      /* :: */[
        Css.flex3(1, 1, /* `rem */[
              5691738,
              0.000000001
            ]),
        /* :: */[
          Css.fontFamily(/* `custom */[
                1066567601,
                Theme$GlazedWebsite.Fonts.heading
              ]),
          /* :: */[
            Css.fontSize(/* `rem */[
                  5691738,
                  0.75
                ]),
            /* :: */[
              Css.fontWeight(/* `num */[
                    5496390,
                    700
                  ]),
              /* :: */[
                Css.lineHeight(/* `rem */[
                      5691738,
                      0.875
                    ]),
                /* :: */[
                  Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                        Css.display(/* flex */-1010954439),
                        /* [] */0
                      ]),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  Variables: Variables,
  nav: nav,
  logoWrapper: logoWrapper,
  logo: logo,
  navBarLinks: navBarLinks
};

var make = React.memo((function (Props) {
        var innerRef = Props.innerRef;
        var className = Props.className;
        var style = Props.style;
        var componentAtTheRight = Props.componentAtTheRight;
        var onNavBarLinkClick = Props.onNavBarLinkClick;
        var useDarkNavBarLinksOpt = Props.useDarkNavBarLinks;
        var currentPageIndexOpt = Props.currentPageIndex;
        var onResize = Props.onResize;
        var useDarkNavBarLinks = useDarkNavBarLinksOpt !== undefined ? useDarkNavBarLinksOpt : false;
        var currentPageIndex = currentPageIndexOpt !== undefined ? currentPageIndexOpt : 0;
        var logoToUse = useDarkNavBarLinks ? LogoGlazedSvg.default : LogoGlazedOutlineSvg.default;
        var onLogoClick = React.useMemo((function () {
                if (onNavBarLinkClick !== undefined) {
                  var handler = onNavBarLinkClick;
                  return (function ($$event) {
                            return Curry._2(handler, $$event, "/#hey");
                          });
                }
                
              }), [onNavBarLinkClick]);
        var tmp = {
          children: null,
          componentTag: "nav"
        };
        if (onResize !== undefined) {
          tmp.onResize = Caml_option.valFromOption(onResize);
        }
        var tmp$1 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
              nav,
              /* :: */[
                className,
                /* [] */0
              ]
            ]);
        if (tmp$1 !== undefined) {
          tmp.className = Caml_option.valFromOption(tmp$1);
        }
        if (innerRef !== undefined) {
          tmp.innerRef = Caml_option.valFromOption(innerRef);
        }
        if (style !== undefined) {
          tmp.style = Caml_option.valFromOption(style);
        }
        var tmp$2 = {
          to: "/#hey",
          className: logoWrapper,
          children: React.createElement(SVG$GlazedWebsite.make, {
                className: logo,
                asset: logoToUse
              })
        };
        if (onLogoClick !== undefined) {
          tmp$2.onClick = Caml_option.valFromOption(onLogoClick);
        }
        var tmp$3 = {
          className: navBarLinks,
          useDarkNavBarLinks: useDarkNavBarLinks,
          currentPageIndex: currentPageIndex
        };
        if (onNavBarLinkClick !== undefined) {
          tmp$3.onNavBarLinkClick = Caml_option.valFromOption(onNavBarLinkClick);
        }
        return React.createElement(ResizeMonitorContainer$GlazedWebsite.make, tmp, React.createElement(Gatsby.Link, tmp$2), React.createElement(NavBarLinks$GlazedWebsite.make, tmp$3), componentAtTheRight !== undefined ? Caml_option.valFromOption(componentAtTheRight) : null);
      }));

var $$default = make;

export {
  Styles ,
  make ,
  $$default ,
  $$default as default,
  
}
/* height Not a pure module */
