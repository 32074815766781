// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReactTransitionGroup from "react-transition-group";

function InOutCSSTransition(Props) {
  var children = Props.children;
  var timeoutOpt = Props.timeout;
  var _in = Props.in;
  var onEnter = Props.onEnter;
  var onEntering = Props.onEntering;
  var onExit = Props.onExit;
  var onExiting = Props.onExiting;
  var onExited = Props.onExited;
  var timeout = timeoutOpt !== undefined ? timeoutOpt : 300;
  var match = React.useState((function () {
          return /* BeforeAppearing */0;
        }));
  var setAnimationState = match[1];
  var handleEnter = React.useCallback((function (param) {
          setTimeout((function (param) {
                  return Curry._1(setAnimationState, (function (_state) {
                                return /* Visible */1;
                              }));
                }), 17);
          if (onEnter !== undefined) {
            return Curry._1(onEnter, /* () */0);
          } else {
            return /* () */0;
          }
        }), /* tuple */[
        setAnimationState,
        onEnter
      ]);
  var handleExit = React.useCallback((function (param) {
          Curry._1(setAnimationState, (function (_state) {
                  return /* Leaving */2;
                }));
          if (onExit !== undefined) {
            return Curry._1(onExit, /* () */0);
          } else {
            return /* () */0;
          }
        }), /* tuple */[
        setAnimationState,
        onExit
      ]);
  var tmp = {
    children: Curry._1(children, match[0]),
    onEnter: handleEnter,
    onExit: handleExit,
    timeout: timeout,
    in: _in
  };
  if (onEntering !== undefined) {
    tmp.onEntering = Caml_option.valFromOption(onEntering);
  }
  if (onExiting !== undefined) {
    tmp.onExiting = Caml_option.valFromOption(onExiting);
  }
  if (onExited !== undefined) {
    tmp.onExited = Caml_option.valFromOption(onExited);
  }
  return React.createElement(ReactTransitionGroup.Transition, tmp);
}

var tick = 17;

var make = InOutCSSTransition;

var $$default = InOutCSSTransition;

export {
  tick ,
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
