// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Utils$GlazedWebsite from "../Utils.js";

var wrapper = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.left(/* zero */-789508312),
          /* :: */[
            Css.width(Css.pct(100)),
            /* :: */[
              Css.height(Css.pct(100)),
              /* :: */[
                Css.zIndex(-1),
                /* :: */[
                  Css.overflow(/* hidden */-862584982),
                  /* :: */[
                    Css.display(/* block */888960333),
                    /* :: */[
                      Css.visibility(/* hidden */-862584982),
                      /* :: */[
                        Css.pointerEvents(/* none */-922086728),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  wrapper: wrapper
};

function ResizeMonitor(Props) {
  var className = Props.className;
  var onResize = Props.onResize;
  var placeholderObjectRef = React.useRef(null);
  var objectDefaultViewRef = React.useRef(null);
  var match = React.useState((function () {
          return false;
        }));
  var setWithData = match[1];
  var dispatchResizeEvent = React.useCallback(Utils$GlazedWebsite.Timing.throttle((function (param) {
              if (onResize !== undefined) {
                var match = placeholderObjectRef.current;
                return Curry._1(onResize, (match == null) ? undefined : Curry._1(Utils$GlazedWebsite.Dom.Measurements.getBoundingClientRect, match));
              } else {
                return /* () */0;
              }
            })), [onResize]);
  var handleContentResize = React.useCallback((function (_event) {
          return Curry._1(dispatchResizeEvent, /* () */0);
        }), [dispatchResizeEvent]);
  var handleLoad = React.useCallback((function (_event) {
          var match = placeholderObjectRef.current;
          if (match == null) {
            return /* () */0;
          } else {
            var objectDefaultView = match.contentDocument.defaultView;
            objectDefaultViewRef.current = objectDefaultView;
            objectDefaultView.addEventListener("resize", handleContentResize);
            return Curry._1(dispatchResizeEvent, /* () */0);
          }
        }), [handleContentResize]);
  var data = match[0] ? "about:blank" : undefined;
  React.useEffect((function () {
          Curry._1(setWithData, (function (_state) {
                  return true;
                }));
          return (function (param) {
                    var match = objectDefaultViewRef.current;
                    if (match == null) {
                      return /* () */0;
                    } else {
                      match.removeEventListener("resize", handleContentResize);
                      return /* () */0;
                    }
                  });
        }), ([]));
  var tmp = {
    ref: placeholderObjectRef,
    "aria-label": "resize monitor placeholder",
    role: "presentation",
    type: "text/html",
    onLoad: handleLoad
  };
  var tmp$1 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        wrapper,
        /* :: */[
          className,
          /* [] */0
        ]
      ]);
  if (tmp$1 !== undefined) {
    tmp.className = Caml_option.valFromOption(tmp$1);
  }
  if (data !== undefined) {
    tmp.data = Caml_option.valFromOption(data);
  }
  return React.createElement("object", tmp);
}

var make = ResizeMonitor;

export {
  Styles ,
  make ,
  
}
/* wrapper Not a pure module */
